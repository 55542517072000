/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable unicorn/no-null */
import React from 'react';
import styles from '../todos.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { TodosContract, TodosStates } from '../../../store/todos/type';
import {
	completeTodos,
	deleteTodo,
	deleteTodosAssignee,
	deleteTodosSelectedMeeting,
	reverseTodos,
	updateTodoAssignee,
	updateTodoSelectMeeting,
	updateTodoSelectMeetingPrev,
	updateTodoState,
	updateTodoStateSelectMeeting,
	updateTodos,
} from '../../../store/todos/slice';

import { TagContract } from '../../../store/meetings/types';
import { IconArchive, IconDelete } from '../../../shared/utils/icon';
import { Avatar } from '../../../shared/utils/avatar';
import { getDateFormatL, getDayPastOneDay, getStartOfDay, getStartOfDayDate } from '../../../shared/utils/date.util';
import { getMeetings, updateSelectedMeetingCountTodos } from '../../../store/meetings/slice';
import { RouteSearchParams, RouteUrls } from '../../../routes/routes-config';
import { useNavigate, useParams } from 'react-router-dom';
import DeleteConfirmBox from '../../../shared/utils/delete-box';
import { SendNotifications } from '../../../store/notifications/slice';
import { NotificationsType } from '../../../store/notifications/type';
import { hexToRgb } from '../../../shared/utils/hextorgb';
import {
	SendNotificationsProject,
	deleteTodoProject,
	reverseTodosProject,
	updateProjectCountTodos,
	updateTodoProject,
	updateTodoStateProject,
} from '../../../store/project/slice';
import { NotificationsProjectType } from '../../../store/project/type';
import {
	deleteTodoShared,
	deleteTodosShare,
	updateTodoMeetingPrevShared,
	updateTodoMeetingShared,
	updateTodoSharedState,
	updateTodosShared,
} from '../../../store/sharing/slice';
import { PersonaSize } from '@fluentui/react';

interface Props {
	todos: TodosContract[];
	meetingIndex: number;
	handleUpdate: (NoteTD: TodosContract) => void;
	isSimpleList?: boolean;
}

const TodosRowComponent: React.FC<Props> = ({ todos, meetingIndex, handleUpdate, isSimpleList = false }) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const paramsID = useParams();
	const shareId = paramsID.shareID;
	const storedAccessMail = localStorage.getItem(`accessMail/${shareId}`);

	const { user } = useAppSelector((state) => state.user);
	const { projects } = useAppSelector((state) => state.project);

	// React.useEffect(() => {
	// 	dispatch(togglePanel(PanelState.Visible));
	// }, [dispatch]);

	const [openConfirmBox, setOpenConfirmBox] = React.useState(false);
	const [todoToUpdate, setTodoToUpdate] = React.useState<TodosContract | undefined>(undefined);

	const handleConfirmBox = () => {
		setOpenConfirmBox(!openConfirmBox);
	};

	const handleTodoClickOption = (NoteTD: TodosContract) => {
		setTodoToUpdate(NoteTD);
	};

	const handleDeleteTodo = () => {
		if (todoToUpdate) {
			if (todoToUpdate.graphiCalUId) {
				dispatch(updateSelectedMeetingCountTodos(todoToUpdate.graphiCalUId));
			}

			if (todoToUpdate.projectId) {
				dispatch(updateProjectCountTodos(todoToUpdate.projectId));
			}

			if (user) {
				if (user?.graphUserId === todoToUpdate.graphUserId) {
					dispatch(deleteTodo(todoToUpdate.id)).then(() => {
						dispatch(deleteTodosAssignee(todoToUpdate.id));
						dispatch(deleteTodoProject(todoToUpdate.id));
						dispatch(deleteTodosSelectedMeeting(todoToUpdate.id));
						setOpenConfirmBox(false);
					});
				}
			} else {
				dispatch(deleteTodoShared(todoToUpdate.id)).then(() => {
					dispatch(deleteTodosShare(todoToUpdate.id));
					setOpenConfirmBox(false);
				});
			}
		}
	};

	const onChangeComplete = (checked: boolean, todo: TodosContract) => {
		const body = {
			state: checked === true ? TodosStates.DONE : TodosStates.TODO,
			index: todo.orderAssignee,
		};

		const bodyUpdate = {
			todoId: todo.id,
			stateTodo: checked === true ? TodosStates.DONE : TodosStates.TODO,
		};

		if (user) {
			dispatch(updateTodoState(bodyUpdate));
			dispatch(updateTodoStateProject(bodyUpdate));
			dispatch(updateTodoStateSelectMeeting(bodyUpdate));

			dispatch(completeTodos({ body, todoId: todo.id })).then((res) => {
				if (res.meta.requestStatus === 'fulfilled') {
					dispatch(updateTodoState(bodyUpdate));
					dispatch(updateTodoStateProject(bodyUpdate));
					dispatch(updateTodoStateSelectMeeting(bodyUpdate));

					if (checked === true && user && user.graphUserId !== todo.graphUserId) {
						const bodyNotification = {
							id: undefined,
							userFromName: user.displayName,
							userFromMail: user.email,
							userToName: todo.graphUserId,
							userToMail: todo.graphUserId,
							type: NotificationsType.TODO_COMPLETE,
							noteId: undefined,
							todoId: todo.id,
							graphiCalUId: undefined,
							projectId: undefined,
							meetingStartDate: undefined,
							itemTitle: todo.title,
							visible: true,
							meetingName: undefined,
						};

						dispatch(SendNotifications(bodyNotification));
					}

					if (checked === true && user && todo.projectId) {
						const bodyNotificationProject = {
							id: '',
							userFromName: user.name,
							userFromMail: user.email,
							type: NotificationsProjectType.TODO_COMPLETE,
							noteId: '',
							todoId: todo.id,
							todoDueDate: todo.dueDate,
							itemTitle: todo.title,
							graphiCalUId: '',
							meetingStartDate: '',
							meetingEndDate: '',
							meetingName: '',
							meetingAttendees: [],
							projectId: todo.projectId,
							userSeenNotif: [],
							todoAssignee: todo.assigneeDisplayName,
						};

						dispatch(SendNotificationsProject(bodyNotificationProject));
					}
				}
			});
		} else if (storedAccessMail?.toLowerCase() === todo.assigneeDisplayName.toLowerCase()) {
			const bodyComplete = {
				id: todo.id,
				title: todo.title,
				text: todo.text,
				state: TodosStates.DONE,
				dueDate: todo.dueDate,
				duration: todo.duration,
				graphUserId: todo.graphUserId,
				noteId: todo.noteId,
				assigneeDisplayName: todo.assigneeDisplayName,
				graphiCalUId: todo.graphiCalUId,
				meetingGraphId: todo.meetingGraphId,
				meetingName: todo.meetingName,
				meetingStartDate: todo.meetingStartDate,
				createdOn: todo.createdOn,
				tags: [],
				assigneeName: todo.assigneeName,
				archived: todo.archived,
				projectId: todo.projectId,
			};

			dispatch(updateTodosShared({ body: bodyComplete, todoId: todo.id })).then(() => {
				dispatch(updateTodoSharedState(bodyComplete));

				if (checked === true && storedAccessMail && storedAccessMail !== todo.graphUserId) {
					const bodyNotification = {
						id: undefined,
						userFromName: storedAccessMail,
						userFromMail: storedAccessMail,
						userToName: todo.graphUserId,
						userToMail: todo.graphUserId,
						type: NotificationsType.TODO_COMPLETE,
						noteId: undefined,
						todoId: todo.id,
						graphiCalUId: undefined,
						projectId: undefined,
						meetingStartDate: undefined,
						itemTitle: todo.title,
						visible: true,
						meetingName: undefined,
					};

					dispatch(SendNotifications(bodyNotification));
				}
			});
		}
	};

	const handleArchivedTodo = (todo: TodosContract) => {
		if (todo) {
			const body = {
				id: todo.id,
				title: todo.title,
				text: todo.text,
				state: todo.state,
				dueDate: todo.dueDate,
				duration: todo.duration,
				graphUserId: todo.graphUserId,
				noteId: todo.noteId,
				assigneeDisplayName: todo.assigneeDisplayName,
				graphiCalUId: todo.graphiCalUId,
				meetingGraphId: todo.meetingGraphId,
				meetingName: todo.meetingName,
				meetingStartDate: todo.meetingStartDate,
				createdOn: todo.createdOn,
				tags: undefined,
				assigneeName: todo.assigneeName,
				archived: todo.archived === false ? true : false,
				projectId: todo.projectId,
			};

			if (user) {
				dispatch(updateTodos({ body, todoId: todo.id })).then((res) => {
					if (res.meta.requestStatus === 'fulfilled') {
						const newTodo = res.payload as TodosContract;

						dispatch(updateTodoAssignee({ todoId: newTodo.id, newTodo }));
						dispatch(updateTodoProject({ todoId: newTodo.id, newTodo }));
						dispatch(updateTodoSelectMeeting({ todoId: newTodo.id, newTodo }));
						dispatch(updateTodoSelectMeetingPrev({ todoId: newTodo.id, newTodo }));
					}
				});
			} else {
				dispatch(updateTodosShared({ body, todoId: todo.id })).then((res) => {
					const newTodo = res.payload as TodosContract;
					dispatch(updateTodoMeetingShared({ todoId: newTodo.id, newTodo }));
					dispatch(updateTodoMeetingPrevShared({ todoId: newTodo.id, newTodo }));
				});
			}
		}
	};

	const [viewTags, setViewTags] = React.useState<number | undefined>(undefined);

	const handleViewTags = (index: number) => {
		setViewTags((prev) => (prev === index ? undefined : index));
	};

	const modalRefTags = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				viewTags !== undefined &&
				modalRefTags.current &&
				!modalRefTags.current.contains(event.target as Node)
			) {
				setTimeout(() => {
					handleViewTags(viewTags);
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleViewTags, viewTags]);

	const [arrow, setArrow] = React.useState(false);

	const handleArrow = () => {
		setArrow(!arrow);
		dispatch(reverseTodos());
		dispatch(reverseTodosProject());
	};

	const currentPath = window.location.pathname;
	const todosPagePath = RouteUrls.Todos;
	const renderTodos =
		currentPath.charAt(currentPath.length - 1) === '/' ? currentPath.slice(0, -1) : currentPath === todosPagePath;

	return (
		<>
			{/* <div className={styles.headTitle} style={{ borderTop: isSimpleList ? 'none' : '' }}>
				<div className={styles.flexHeader}>
					<div className={styles.title} style={{ color: '#2E4552' }}>
						<IconText /> {t('Todos')}
					</div>
					<div>
						{arrow === false && (
							<span
								style={{ cursor: 'pointer' }}
								onClick={() => {
									handleArrow();
								}}>
								<IconUp />
							</span>
						)}
						{arrow === true && (
							<span
								style={{ cursor: 'pointer' }}
								onClick={() => {
									handleArrow();
								}}>
								<IconDown />
							</span>
						)}
					</div>
				</div>

				<div className={styles.titleHeader}>
					<div className={styles.title}>
						<IconMeetings />
						{t('Due date')}
					</div>
				</div>
				{!renderTodos && (
					<div className={styles.titleHeader}>
						<div className={styles.title}>
							<IconContact />
							{t('Assignees')}
						</div>
					</div>
				)}
				<div className={styles.titleHeader}>
					<div className={styles.title}>
						<IconTag /> {t('Tags')}
					</div>
				</div>
				{renderTodos && (
					<div className={styles.titleHeader}>
						<div className={styles.title}>
							<IconMeetingsPage /> {t('Connected meeting')}
						</div>
					</div>
				)}
				{renderTodos && (
					<div className={styles.titleHeader}>
						<div className={styles.title}>
							<IconMeetingsPage /> {t('Connected project')}
						</div>
					</div>
				)}

				<div className={styles.endColumn}></div>
			</div> */}
			{todos.map((todo: TodosContract, index: number) => {
				const uniqueIndex = meetingIndex * 1000 + index;

				return (
					<div
						key={index}
						onClick={() => {
							handleUpdate(todo);
						}}
						style={{
							cursor: 'pointer',
							opacity: todo.state === TodosStates.DONE || todo.archived ? 0.5 : 1,
						}}
						className={styles.todoRow}>
						<div className={styles.titleTodo}>
							<div className={styles.flex}>
								<div className={styles.todoChekbox}>
									<input
										type='checkbox'
										onClick={(e) => e.stopPropagation()}
										onChange={(e) => {
											onChangeComplete(e.target.checked, todo);
											e.stopPropagation();
										}}
										checked={todo.state === TodosStates.DONE ? true : false}
									/>
								</div>
								<p className={styles.titleTodoInput}>{todo.title}</p>
							</div>
						</div>

						<div className={styles.todoRowColumn}>
							{todo.dueDate === 'select' ||
							todo.dueDate === 'Invalid Date' ||
							todo.dueDate === null ||
							todo.dueDate === undefined ||
							!todo.dueDate ? (
								<span>{t('No due date')}</span>
							) : (
								<>
									<span>{getDateFormatL(todo.dueDate)}</span>
								</>
							)}
						</div>

						{!renderTodos && (
							<div className={styles.todoRowColumn}>
								{todo.assigneeDisplayName === null ? (
									<span>{t('-')}</span>
								) : (
									<div>
										<Avatar
											size={PersonaSize.size28}
											name={todo.assigneeName}
											mail={todo.assigneeDisplayName}
											index={uniqueIndex}
										/>
									</div>
								)}
							</div>
						)}

						<div className={styles.todoRowColumn}>
							<div className={styles.tags}>
								{todo.tags?.slice(0, 2).map((tag: TagContract, indexTodoTag) => (
									<span
										key={indexTodoTag}
										className={styles.tag}
										style={{
											backgroundColor: `rgba(${hexToRgb(tag.color)}, 0.1)`,
											border: `1px solid ${tag.color}`,
										}}>
										<div style={{ color: tag.color, fontWeight: '600' }}>{tag.title}</div>
									</span>
								))}
								{todo.tags.length > 2 && (
									<div
										className={styles.plus}
										style={{ cursor: 'pointer' }}
										onClick={(e) => {
											handleViewTags(uniqueIndex);
											e.stopPropagation();
										}}>
										<div>+{todo.tags.length - 2}</div>
									</div>
								)}
								{viewTags === uniqueIndex && (
									<div className={styles.modalAttendees} ref={modalRefTags}>
										<div className={styles.tags}>
											{todo.tags.slice(1, todo.tags.length).map((tag: TagContract, indexTag) => (
												<div
													key={indexTag}
													className={styles.tag}
													style={{
														backgroundColor: `rgba(${hexToRgb(tag.color)}, 0.1)`,
														border: `1px solid ${tag.color}`,
													}}>
													<div style={{ color: tag.color, fontWeight: '600' }}>
														{tag.title}
													</div>
												</div>
											))}
										</div>
									</div>
								)}
							</div>
						</div>
						{renderTodos && (
							<div className={styles.todoRowColumn}>
								<span
									className={styles.linkMeeting}
									onClick={(e) => {
										e.stopPropagation();
										const startDate = getStartOfDayDate(new Date(todo.meetingStartDate));
										const endDate = getDayPastOneDay(new Date(todo.meetingStartDate));
										const dateRange = { startDate, endDate };
										dispatch(getMeetings(dateRange)).then(() => {
											const params: any = {};
											params[RouteSearchParams.Date] = getStartOfDay(todo.meetingStartDate);
											params[RouteSearchParams.MeetingId] = todo.graphiCalUId;
											navigate(`${RouteUrls.Meetings}?${new URLSearchParams(params).toString()}`);
										});
									}}>
									{todo.meetingName}
								</span>
							</div>
						)}
						{renderTodos && (
							<div className={styles.todoRowColumn}>
								<span
									className={styles.linkMeeting}
									onClick={(e) => {
										e.stopPropagation();

										const params: any = {};
										params[RouteSearchParams.ProjectId] = todo.projectId;
										navigate(`${RouteUrls.Project}?${new URLSearchParams(params).toString()}`);
									}}>
									{projects?.find((project) => project.id === todo.projectId)?.projectName}
								</span>
							</div>
						)}

						<div className={styles.more}>
							{user?.graphUserId === todo.graphUserId && (
								<>
									<div
										onClick={(e) => {
											handleTodoClickOption(todo);
											setOpenConfirmBox(!openConfirmBox);
											e.stopPropagation();
										}}
										className={styles.icon}>
										<IconDelete />
									</div>
									<div
										onClick={(e) => {
											handleArchivedTodo(todo);
											e.stopPropagation();
										}}
										className={styles.icon}>
										<IconArchive />
									</div>
								</>
							)}
						</div>
					</div>
				);
			})}

			{openConfirmBox && todoToUpdate && (
				<DeleteConfirmBox
					handleDelete={handleDeleteTodo}
					handleConfirmBox={handleConfirmBox}
					message='Are you sure you want to delete this todo?'
				/>
			)}
		</>
	);
};

export default TodosRowComponent;
