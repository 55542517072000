/* eslint-disable quotes */
import React from 'react';
import styles from './copilot.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import CopilotTranscriptModal from './modal/copilot-transcript.modal';
import { IconAnalytics, IconSend } from '../../../../../../shared/utils/icon';
import {
	createDataMeetingBot,
	deleteDataMeetingBot,
	generateMistralSummary,
	generateMistralTodos,
	getDataMeetingBot,
	getDataMeetingBotTranscript,
	setTodosMistral,
	updateSelectMeetingDataBot,
} from '../../../../../../store/recall/slice';
import {
	BotNotesContract,
	BotStatus,
	DataMeetingBotContract,
	MistralCall,
	MistralTodos,
} from '../../../../../../store/recall/type';
import { toast } from '../../../../../../shared/components/modals/toast/toast-manager';
import CopilotSummarytModal from './modal/copilot-summary.modal';
import { TodosContract, TodosStates } from '../../../../../../store/todos/type';
import CopilotTodosModal from './modal/copilot-todos-modal';
import { getQuestionTemplate, getListTemplate } from '../../../../../../shared/types/template-mistral';
import useClickOutside from '../../../../../../shared/hooks/use-click-outside.hook';
import useBotStatus, { Status } from '../../../../../../shared/hooks/use-bot-status.hook';

const CopilotComponent = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { dataMeetingBot, dataMeetingBotTranscript } = useAppSelector((state) => state.recall);
	const { selectedMeeting } = useAppSelector((state) => state.meetings);
	const { user } = useAppSelector((state) => state.user);
	const [openTranscript, setOpenTranscript] = React.useState(false);
	const [openSummary, setOpenSummary] = React.useState(false);
	const [openTodos, setOpenTodos] = React.useState(false);
	const [isBot, setIsBot] = React.useState<boolean>(dataMeetingBot ? true : false);
	const [status, setStatus] = React.useState<Status>();
	const [questionMistral, setQuestionMistral] = React.useState('');
	const [templateSum, setTemplateSum] = React.useState<string>('');
	const [dropdown, setDropdown] = React.useState(false);

	const handleDropDown = () => {
		setDropdown(!dropdown);
	};

	const modalRef = React.useRef<HTMLDivElement>(null);

	useClickOutside(modalRef, () => {
		setTimeout(() => {
			if (dropdown) handleDropDown();
		}, 150);
	});

	React.useEffect(() => {
		if (selectedMeeting) {
			dispatch(getDataMeetingBot(selectedMeeting.iCalUId)).then((res) => {
				const data = res.payload;
				if (data === false) {
					setIsBot(false);
				} else {
					setIsBot(true);
				}
			});
		}
	}, [selectedMeeting]);

	React.useEffect(() => {
		if (
			selectedMeeting &&
			(!dataMeetingBotTranscript || dataMeetingBotTranscript.graphiCalUId !== selectedMeeting.iCalUId) &&
			dataMeetingBot &&
			(dataMeetingBot.status === BotStatus.analysis_done || dataMeetingBot.status === BotStatus.media_expired)
		) {
			dispatch(getDataMeetingBotTranscript(dataMeetingBot.botId));
		}
	}, [dataMeetingBot, selectedMeeting, dataMeetingBotTranscript]);

	React.useEffect(() => {
		const currentStatus = useBotStatus(dataMeetingBot?.status);
		setStatus(currentStatus);
	}, [dataMeetingBot?.status]);

	const handleIsBot = (newData: Boolean) => {
		if (user) {
			if (selectedMeeting && !dataMeetingBot && newData === true) {
				if (selectedMeeting.isOnlineMeeting) {
					const bodyDMB: DataMeetingBotContract = {
						id: '',
						graphiCalUId: selectedMeeting.iCalUId,
						onlineMeetingUrl: selectedMeeting.onlineMeeting,
						meetingStartDate: selectedMeeting.start,
						meetingEndDate: selectedMeeting.end,
						botId: '',
						userGraphId: '',
						userMail: '',
						meetingName: selectedMeeting.subject,
						createdOn: new Date(),
						updatedOn: new Date(),
					};

					dispatch(createDataMeetingBot(bodyDMB)).then((res) => {
						const newDataBot = res.payload as DataMeetingBotContract;
						dispatch(updateSelectMeetingDataBot(newDataBot));
						setIsBot(true);
					});
				} else if (selectedMeeting.googleMeetUrl) {
					const bodyDMB: DataMeetingBotContract = {
						id: '',
						graphiCalUId: selectedMeeting.iCalUId,
						onlineMeetingUrl: selectedMeeting.googleMeetUrl,
						meetingStartDate: selectedMeeting.start,
						meetingEndDate: selectedMeeting.end,
						botId: '',
						userGraphId: '',
						userMail: '',
						meetingName: selectedMeeting.subject,
						createdOn: new Date(),
						updatedOn: new Date(),
					};

					dispatch(createDataMeetingBot(bodyDMB)).then((res) => {
						const newDataBot = res.payload as DataMeetingBotContract;
						dispatch(updateSelectMeetingDataBot(newDataBot));
						// dispatch(updateUserCreditCopilot(user.creditCopilot - 1));
						// setIsBot(true);

						dispatch(getDataMeetingBot(selectedMeeting.iCalUId)).then((resD) => {
							const data = resD.payload;

							if (data === false) {
								setIsBot(false);
							} else {
								setIsBot(true);
							}
						});
					});
				} else {
					toast.show({
						id: user?.graphUserId,
						title: t('Failed to start the bot IA') as string,
						duration: 10000,
						type: 'failed',
					});

					setIsBot(false);
				}
			} else if (dataMeetingBot) {
				dispatch(deleteDataMeetingBot(dataMeetingBot.graphiCalUId)).then((res) => {
					const data = res.payload;

					if (data !== false) {
						dispatch(updateSelectMeetingDataBot(undefined));
						setIsBot(false);
					} else {
						setIsBot(true);
						toast.show({
							id: user?.graphUserId,
							title: t('Only scheduled bots which have not yet joined a call can be deleted') as string,
							duration: 10000,
							type: 'failed',
						});
					}
				});
			}
		}
	};

	const handleOpenTranscript = () => {
		setOpenTranscript(!openTranscript);
	};

	const handleOpenSummary = () => {
		setOpenSummary(!openSummary);
	};

	const getTranscript = () => {
		if (dataMeetingBot && selectedMeeting) {
			if (!dataMeetingBotTranscript || dataMeetingBotTranscript.graphiCalUId !== selectedMeeting.iCalUId) {
				dispatch(getDataMeetingBotTranscript(dataMeetingBot.botId));
			}

			handleOpenTranscript();
		} else {
			toast.show({
				id: user?.graphUserId,
				title: t('Failed to get the transcript') as string,
				duration: 10000,
				type: 'failed',
			});
		}
	};

	const handleGenerateSummaryMistral = (question: string) => {
		if (dataMeetingBot && selectedMeeting) {
			handleOpenSummary();

			if (!dataMeetingBotTranscript || dataMeetingBotTranscript.graphiCalUId !== selectedMeeting.iCalUId) {
				dispatch(getDataMeetingBotTranscript(dataMeetingBot.botId)).then((result) => {
					const data = result.payload as BotNotesContract;

					const body: MistralCall = {
						botId: data.botId,
						question,
						transcript: data.transcript,
					};
					handleDropDown();
					dispatch(generateMistralSummary(body)).then(() => {});
					setTemplateSum('');
				});
			} else {
				const body: MistralCall = {
					botId: dataMeetingBotTranscript.botId,
					question,
					transcript: dataMeetingBotTranscript.transcript,
				};
				handleDropDown();
				dispatch(generateMistralSummary(body)).then(() => {});
				setTemplateSum('');
			}
		} else {
			toast.show({
				id: user?.graphUserId,
				title: t('Failed to get the summary') as string,
				duration: 10000,
				type: 'failed',
			});
		}
	};

	const handleOpenTodos = () => {
		setOpenTodos(!openTodos);
	};

	const handleGenerateTodosMistral = async () => {
		if (dataMeetingBot && selectedMeeting) {
			dispatch(setTodosMistral(undefined));
			handleOpenTodos();

			if (!dataMeetingBotTranscript || dataMeetingBotTranscript.graphiCalUId !== selectedMeeting.iCalUId) {
				dispatch(getDataMeetingBotTranscript(dataMeetingBot.botId)).then(async (result) => {
					const data = result.payload as BotNotesContract;

					const body: MistralCall = {
						botId: data.botId,
						question: '',
						transcript: data.transcript,
					};
					await dispatch(generateMistralTodos(body)).then((res) => {
						console.log(res);
						const newTodos = res.payload as MistralTodos[];
						const todosArray: TodosContract[] = [];
						for (const element of newTodos) {
							const attendeeEmails = selectedMeeting
								? selectedMeeting.attendees.map((attendee) =>
										attendee.emailAddress.address.toLowerCase(),
								  )
								: [];

							const rightAttendee = selectedMeeting
								? selectedMeeting.attendees.find(
										(att) =>
											att.emailAddress.name.toLocaleLowerCase() ===
											element.assignee.toLocaleLowerCase(),
								  )
								: {
										type: 'attendee',
										emailAddress: {
											name: user?.name,
											address: user?.userName,
										},
								  };

							const bodyT: any = {
								id: newTodos.indexOf(element),
								title: element.todo,
								text: '',
								state: TodosStates.TODO,
								dueDate: undefined,
								duration: undefined,
								graphUserId: user?.graphUserId,
								noteId: undefined,
								assigneeDisplayName: rightAttendee
									? rightAttendee.emailAddress.address
									: user?.userName,
								graphiCalUId: selectedMeeting?.iCalUId,
								meetingGraphId: selectedMeeting?.graphEventId,
								meetingName: selectedMeeting?.subject,
								meetingStartDate: selectedMeeting?.start,
								createdOn: new Date().toISOString(),
								tags: [],
								projectId: '',
								assigneeName: rightAttendee ? rightAttendee.emailAddress.name : user?.name,
								meetingAttendees: attendeeEmails,
							};

							todosArray.push(bodyT);
						}
						dispatch(setTodosMistral(todosArray));
					});
				});
			} else {
				const body: MistralCall = {
					botId: dataMeetingBotTranscript.botId,
					question: '',
					transcript: dataMeetingBotTranscript.transcript,
				};
				await dispatch(generateMistralTodos(body)).then((res) => {
					const newTodos = res.payload as MistralTodos[];
					console.log(res);
					const todosArray: TodosContract[] = [];
					for (const element of newTodos) {
						const attendeeEmails = selectedMeeting
							? selectedMeeting.attendees.map((attendee) => attendee.emailAddress.address.toLowerCase())
							: [];

						const rightAttendee = selectedMeeting
							? selectedMeeting.attendees.find(
									(att) =>
										att.emailAddress.name.toLocaleLowerCase() ===
										element.assignee.toLocaleLowerCase(),
							  )
							: {
									type: 'attendee',
									emailAddress: {
										name: user?.name,
										address: user?.userName,
									},
							  };

						const bodyT: any = {
							id: newTodos.indexOf(element),
							title: element.todo,
							text: '',
							state: TodosStates.TODO,
							dueDate: undefined,
							duration: undefined,
							graphUserId: user?.graphUserId,
							noteId: undefined,
							assigneeDisplayName: rightAttendee ? rightAttendee.emailAddress.address : user?.userName,
							graphiCalUId: selectedMeeting?.iCalUId,
							meetingGraphId: selectedMeeting?.graphEventId,
							meetingName: selectedMeeting?.subject,
							meetingStartDate: selectedMeeting?.start,
							createdOn: new Date().toISOString(),
							tags: [],
							projectId: '',
							assigneeName: rightAttendee ? rightAttendee.emailAddress.name : user?.name,
							meetingAttendees: attendeeEmails,
						};

						todosArray.push(bodyT);
					}
					dispatch(setTodosMistral(todosArray));
				});
			}
		} else {
			toast.show({
				id: user?.graphUserId,
				title: t('Failed to get todos') as string,
				duration: 10000,
				type: 'failed',
			});
		}
	};

	return (
		<div className={styles.copilotContainer}>
			<div>
				<div className={styles.copilotPreInfo}>
					<div>
						<IconAnalytics />
					</div>
					<div>
						<p>
							{t(
								'Copilot knows about the context of this meeting. It has access to previous meeting notes, tasks and decisions.',
							)}
						</p>
					</div>
				</div>
				<div className={styles.flexButton}>
					{dataMeetingBot?.status === undefined && (
						<div className={styles.isOnline}>
							<div className={styles.copilotWrapper}>
								<div>
									<IconAnalytics />
								</div>
								<div>
									<div className={styles.teams}>
										{isBot
											? t('Copilot is programmed for this meeting.')
											: t('Copilot is not programmed for this meeting.')}
									</div>
									<div className={styles.teamsBtn}>
										{isBot
											? t('Click on the button to disable Copilot.')
											: t('Click on the button to activate Copilot.')}
									</div>
								</div>
							</div>
							<label className={styles.switch}>
								<input
									type='checkbox'
									onChange={() => handleIsBot(!isBot)}
									checked={isBot}
									disabled={user && user.creditCopilot > 0 ? false : true}
								/>
								<span className={styles.slider}></span>
							</label>
						</div>
					)}
					{dataMeetingBot?.status && (
						<>
							{dataMeetingBot.status !== BotStatus.done &&
							dataMeetingBot.status !== BotStatus.recording_done &&
							dataMeetingBot.status !== BotStatus.analysis_done &&
							dataMeetingBot.status !== BotStatus.media_expired &&
							dataMeetingBot.status !== BotStatus.analysis_failed ? (
								<div className={styles.isOnline}>
									<div className={styles.copilotWrapper}>
										<div>
											<IconAnalytics />
										</div>
										<div>
											<div className={styles.teams}>
												{t('The meeting is in progress. Copilot is activated.')}
											</div>
											<div className={styles.botStatus}>
												{status?.icon}
												<p>{t(status?.text ?? '')}</p>
											</div>
										</div>
									</div>
									<label className={styles.switch}>
										<input type='checkbox' onChange={() => handleIsBot(!isBot)} checked={isBot} />
										<span className={styles.slider}></span>
									</label>
								</div>
							) : (
								<div className={styles.isOnline}>
									<div className={styles.copilotWrapper}>
										<div>
											<IconAnalytics />
										</div>
										<div>
											<div className={styles.teams}>{t('The meeting is over.')}</div>

											{dataMeetingBot?.status === BotStatus.done ? (
												<div style={{ marginTop: '20px' }} className={styles.botStatus}>
													{status?.icon}
													<p>{t(status?.text ?? '')}</p>
												</div>
											) : (
												<>
													{dataMeetingBot.status === BotStatus.media_expired && (
														<div style={{ marginTop: '20px' }} className={styles.botStatus}>
															{status?.icon}
															<p>{t(status?.text ?? '')}</p>
														</div>
													)}
												</>
											)}

											{user && dataMeetingBot.status === BotStatus.analysis_done && (
												<>
													<div className={styles.options}>
														<h4 onClick={() => getTranscript()}>
															{t('Meeting transcript')}
														</h4>
													</div>
													<div className={styles.options}>
														<h4
															onClick={() => {
																handleGenerateSummaryMistral(
																	getListTemplate(user.preferences.language),
																);
															}}>
															{t('Generate a note')}
														</h4>
													</div>
													<div className={styles.options}>
														<h4 onClick={() => handleGenerateTodosMistral()}>
															{t('Generate todos')}
														</h4>
													</div>
												</>
											)}

											{user &&
												dataMeetingBot.status === BotStatus.media_expired &&
												dataMeetingBotTranscript && (
													<>
														<div className={styles.options}>
															<h4 onClick={() => getTranscript()}>
																{t('Meeting transcript')}
															</h4>
														</div>
														<div className={styles.options}>
															<h4
																onClick={() => {
																	handleGenerateSummaryMistral(
																		getListTemplate(user.preferences.language),
																	);
																}}>
																{t('Generate a note')}
															</h4>
														</div>
														<div className={styles.options}>
															<h4 onClick={() => handleGenerateTodosMistral()}>
																{t('Generate todos')}
															</h4>
														</div>
													</>
												)}
										</div>
									</div>
								</div>
							)}
						</>
					)}
				</div>
				{/* <div className={styles.creditContainer}>
					<div className={styles.creditInfo}>
						<div>
							<IconAnalytics />
						</div>
						<div>
							<p>{t("You don't have much credit left")}</p>
							<p className={styles.creditSubTitle}>
								{t('Add credits to continue enjoying Copilot features.')}
							</p>
							<p className={styles.creditLeft}>
								{user?.creditCopilot} {t('credits left')}
							</p>
						</div>
					</div>
					<button className={styles.creditBtn}>{t('Buy credits')}</button>
				</div> */}
			</div>

			<div>
				{dataMeetingBot?.status === BotStatus.analysis_done && user && (
					<>
						<div className={styles.promptInput}>
							<IconAnalytics width='24' height='24' />
							<input
								spellCheck={true}
								type='text'
								placeholder={
									t('Based on the context, ask for information about this meeting') as string
								}
								onChange={(e) =>
									setQuestionMistral(getQuestionTemplate(user.preferences.language, e.target.value))
								}
							/>
							<div className={styles.send} onClick={() => handleGenerateSummaryMistral(questionMistral)}>
								<IconSend />
							</div>
						</div>
					</>
				)}

				{dataMeetingBot?.status === BotStatus.media_expired && user && (
					<>
						<div className={styles.promptInput}>
							<IconAnalytics />
							<input
								type='text'
								placeholder={
									t('Based on the context, ask for information about this meeting') as string
								}
								onChange={(e) =>
									setQuestionMistral(getQuestionTemplate(user.preferences.language, e.target.value))
								}
							/>
							<div className={styles.send} onClick={() => handleGenerateSummaryMistral(questionMistral)}>
								<IconSend />
							</div>
						</div>
					</>
				)}
			</div>

			{openTranscript && <CopilotTranscriptModal handleClose={handleOpenTranscript} />}

			{openSummary && <CopilotSummarytModal handleClose={handleOpenSummary} />}

			{openTodos && <CopilotTodosModal handleClose={handleOpenTodos} />}
		</div>
	);
};

export default CopilotComponent;
