import React from 'react';
import { hexToRgb } from '../../../shared/utils/hextorgb';
import { IconClose } from '../../utils/icon';
import styles from './tags.module.scss';
import { TagContract } from '../../../store/meetings/types';

interface Props {
    tag: TagContract,
    deleteTag?: (tag: TagContract) => void
}

const Tags: React.FC<Props> = ({ tag, deleteTag }) => {
    
    return (
        <div
            style={{
                backgroundColor: `rgba(${hexToRgb(tag.color)}, 0.1)`,
                border: `1px solid ${tag.color}`,
            }}
            className={styles.tag}
        >
            <div
                style={{
                    fontWeight: '600',
                    color: tag.color,
                }}>
                {tag.title}
            </div>
            {deleteTag && 
                <div
                    style={{ fontWeight: '600', color: tag.color }}
                    className={styles.iconDelete}
                    onClick={() => {
                        deleteTag(tag);
                    }}>
                    <IconClose />
                </div>
            }
        </div>
    );
};

export default Tags;