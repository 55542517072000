/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState } from 'react';
import styles from './meetings-details-agenda.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { AgendaContract } from '../../../../../../store/agenda/type';
import { IconDelete, IconDrop } from '../../../../../../shared/utils/icon';
import { Avatar } from '../../../../../../shared/utils/avatar';
import {
	deleteAgendaItem,
	getAllAgendaItemForAMeeting,
	removeAgendaItems,
	sortAgenda,
	updateAgendaItem,
	updateAgendaItemList,
} from '../../../../../../store/agenda/slice';
import {
	deleteAgendaItemShared,
	deleteAgendaShare,
	getAgendaForAMeetingShared,
	updateAgendaItemShared,
} from '../../../../../../store/sharing/slice';
import { MeetingsDetailsAgendaUpdate } from './meetings-details-agenda-update';
import DeleteConfirmBox from '../../../../../../shared/utils/delete-box';
import { getHourFormat } from '../../../../../../shared/utils/date.util';
import { isSameDay, isAfter } from 'date-fns';
import { PersonaSize } from '@fluentui/react';
import useClickOutside from '../../../../../../shared/hooks/use-click-outside.hook';
import { updateSelectedMeetingCount } from '../../../../../../store/meetings/slice';

interface Props {
	agenda?: AgendaContract[];
}

const MeetingsDetailsAgendaTable: React.FC<Props> = ({ agenda }) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { selectedMeeting } = useAppSelector((state) => state.meetings);
	const { user } = useAppSelector((state) => state.user);
	const { shareMeeting } = useAppSelector((state) => state.sharing);

	type FormData = {
		duration: string;
		description: string;
		assignee: string;
	};

	const dataSchema = yup.object().shape({
		duration: yup.string().required(),
		description: yup.string(),
		assignee: yup.string(),
	});

	const { reset } = useForm<FormData>({
		resolver: yupResolver(dataSchema),
	});

	const [openConfirmBox, setOpenConfirmBox] = React.useState(false);
	const [openUpdate, setOpenUpdate] = React.useState(false);
	const [agendaItemToUpdate, setAgendaItemToUpdate] = React.useState<AgendaContract | undefined>(undefined);
	const [agendaItemToDelete, setAgendaItemToDelete] = React.useState<AgendaContract | undefined>(undefined);

	const [dragItem, setDragItem] = React.useState<AgendaContract>();
	const [isDraggedOver, setIsDraggedOver] = React.useState<string | undefined>(undefined);
	const agendaRef = useRef<HTMLDivElement | null>(null);

	const handleAgendaItemClickOption = (agendaIt: AgendaContract) => {
		setAgendaItemToDelete(agendaIt);
		setOpenConfirmBox(true);
	};

	const handleOpenConfirmBox = () => {
		setOpenConfirmBox(!openConfirmBox);
	};

	React.useEffect(() => {
		reset(agendaItemToUpdate);
	}, [agendaItemToUpdate, reset]);

	const handleAgendaItemOpenUpdate = (agendaIt: AgendaContract) => {
		setAgendaItemToUpdate(agendaIt);
		setOpenUpdate(true);
	};

	const handleOpenUpdate = () => {
		setOpenUpdate(!openUpdate);
	};

	const handleDeleteAgendaItem = () => {
		if (agendaItemToDelete && selectedMeeting) {
			const bodyUpCount = {
				iCalUId: selectedMeeting.iCalUId,
				noteCount: selectedMeeting.countNotes ? +selectedMeeting.countNotes : 0,
				agendaCount: selectedMeeting.countAgenda ? +selectedMeeting.countAgenda - 1 : 0,
				todoCount: selectedMeeting.countTodos ? +selectedMeeting.countTodos : 0,
			};

			dispatch(updateSelectedMeetingCount(bodyUpCount));

			if (user) {
				dispatch(deleteAgendaItem(agendaItemToDelete.id)).then(() => {
					dispatch(removeAgendaItems(agendaItemToDelete.id));
					setOpenConfirmBox(false);
				});
			} else {
				dispatch(deleteAgendaItemShared(agendaItemToDelete.id)).then(() => {
					dispatch(deleteAgendaShare(agendaItemToDelete.id));
					setOpenConfirmBox(false);
				});
			}
		}
	};

	const handleDragStart = (e: any, index: number, id: string) => {
		e.dataTransfer.setData('text/plain', index.toString());

		const board = document.querySelector('#agendaBoard');
		const element: HTMLDivElement | null = document.querySelector(`#agenda-item-${id}`);

		if (element && board) {
			const clone = element.cloneNode(true) as HTMLDivElement;
			const rect = element.getBoundingClientRect();

			element.classList.add('drag');

			const offsetX = e.clientX - rect.left + 425;
			const offsetY = e.clientY - rect.top + 260;

			clone.style.position = 'absolute';
			clone.style.zIndex = '999';
			clone.style.width = `${rect.width}px`;
			clone.style.opacity = '.5';
			clone.style.pointerEvents = 'none';

			const startX = e.clientX - offsetX;
			const startY = e.clientY - offsetY;

			clone.style.left = `${startX}px`;
			clone.style.top = `${startY}px`;

			const updatePosition = (event: MouseEvent) => {
				clone.classList.add('drag-clone');
				const x = event.clientX - offsetX;
				const y = event.clientY - offsetY;

				clone.style.left = `${x}px`;
				clone.style.top = `${y}px`;
			};

			board.appendChild(clone);

			const handleDragEnd = () => {
				clone.remove();
				element.classList.remove('drag');
				document.removeEventListener('drag', updatePosition);
				document.removeEventListener('dragend', handleDragEnd);
			};

			document.addEventListener('drag', updatePosition);
			document.addEventListener('dragend', handleDragEnd);

			const dragImage = new Image();
			dragImage.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
			e.dataTransfer.setDragImage(dragImage, 0, 0);
		}
	};

	const [agendaItemDrop, setAgendaItemDrop] = React.useState<AgendaContract>();

	useClickOutside(agendaRef, () => setAgendaItemDrop(undefined));

	const handleDrop = (dropIndex: number) => {
		if (user && selectedMeeting && agenda && agendaItemDrop) {
			const body = {
				id: agendaItemDrop.id,
				userCreatorId: agendaItemDrop.userCreatorId,
				userMail: agendaItemDrop.userMail,
				duration: agendaItemDrop.duration,
				description: agendaItemDrop.description,
				graphiCalUId: agendaItemDrop.graphiCalUId,
				orderNumber: dropIndex.toString(),
			};

			const element = document.querySelector(`#agenda-item-${agendaItemDrop.id}`);
			const clone = document.querySelector('.drag-clone');
			if (element && clone) {
				element.classList.remove('drag');
				clone?.remove();
			}

			dispatch(updateAgendaItemList(body));
			dispatch(sortAgenda());

			dispatch(updateAgendaItem(body));
		} else if (shareMeeting && agenda && agendaItemDrop) {
			const body = {
				id: agendaItemDrop.id,
				userCreatorId: agendaItemDrop.userCreatorId,
				userMail: agendaItemDrop.userMail,
				duration: agendaItemDrop.duration,
				description: agendaItemDrop.description,
				graphiCalUId: agendaItemDrop.graphiCalUId,
				orderNumber: (dropIndex + 1).toString(),
			};

			dispatch(updateAgendaItemShared(body)).then(() => {
				const element = document.querySelector(`#agenda-item-${agendaItemDrop.id}`);
				const clone = document.querySelector('.drag-clone');
				if (element && clone) {
					element.classList.remove('drag');
					clone?.remove();
				}

				dispatch(getAgendaForAMeetingShared(shareMeeting.iCalUId));
			});
		}
	};

	let cumulativeTime = selectedMeeting?.start ? new Date(selectedMeeting.start) : new Date();

	const [nowTime, setNowTime] = React.useState(new Date());

	React.useEffect(() => {
		const interval = setInterval(() => {
			setNowTime(new Date());
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	return (
		<div id='agendaBoard' ref={agendaRef}>
			{agenda &&
				agenda.map((agendaItem: AgendaContract, index) => {
					const duration = Number.parseInt(agendaItem.duration, 10);

					if (Number.isNaN(duration)) {
						console.error(`Invalid duration for agenda item ${index + 1}`);
						return undefined;
					}

					const startTime = new Date(cumulativeTime);

					const endTime = new Date(startTime.getTime() + duration * 60000);

					cumulativeTime = endTime;

					const isNow =
						selectedMeeting &&
						isSameDay(new Date(), new Date(selectedMeeting.start)) &&
						nowTime >= startTime &&
						nowTime <= endTime;
					const isPast =
						nowTime > endTime || (selectedMeeting && isAfter(new Date(selectedMeeting.start), new Date()));

					return (
						<div
							style={{
								backgroundColor: dragItem?.id === agendaItem.id ? '#efeeee' : '',
							}}
							className={styles.shadow}
							key={agendaItem.id}>
							<div
								className={styles.placeholder}
								style={{
									backgroundColor:
										isDraggedOver === agendaItem.id &&
										isDraggedOver !== undefined &&
										dragItem?.id !== agendaItem.id &&
										dragItem !== undefined
											? '#00ad56'
											: '',
								}}
								onDrop={() => {
									handleDrop(Number(agendaItem.orderNumber));
									setDragItem(undefined);
									setIsDraggedOver(undefined);
								}}
								onDragOver={(e) => {
									e.dataTransfer.dropEffect = 'move';
									setIsDraggedOver(agendaItem.id);
									e.preventDefault();
								}}
								onDragEnter={(e) => e.preventDefault()}
								onDragLeave={() => {
									if (isDraggedOver !== agendaItem.id) {
										setIsDraggedOver(undefined);
									}
								}}
								onDragEnd={() => {
									setDragItem(undefined);
									setIsDraggedOver(undefined);
								}}
							/>
							<div
								draggable={true}
								onDragStart={(e) => {
									e.dataTransfer.effectAllowed = 'move';
									handleDragStart(e, index, agendaItem.id);
									setAgendaItemDrop(agendaItem);
									setDragItem(agendaItem);
								}}
								onDragOver={(e) => {
									e.dataTransfer.dropEffect = 'move';
									setIsDraggedOver(agendaItem.id);
									e.preventDefault();
								}}
								onDragLeave={() => {
									if (isDraggedOver !== agendaItem.id) {
										setIsDraggedOver(undefined);
									}
								}}
								onDragEnter={(e) => e.preventDefault()}
								onDragEnd={() => {
									setIsDraggedOver(undefined);
									setDragItem(undefined);
								}}
								onDrop={() => {
									handleDrop(Number(agendaItem.orderNumber));
									setDragItem(undefined);
									setIsDraggedOver(undefined);
								}}
								className={styles.rows}
								id={`agenda-item-${agendaItem.id}`}
								key={index}
								style={{
									cursor: 'pointer',
									backgroundColor: isPast ? '#F7F7F7' : 'white',
									borderColor: agendaItemDrop?.id === agendaItem.id ? '#00ad56' : '',
								}}
								onClick={() => {
									handleAgendaItemOpenUpdate(agendaItem);
								}}>
								<div className={styles.left}>
									<p>{`${getHourFormat(startTime)} - ${getHourFormat(endTime)}`}</p>
									<p>{agendaItem.description}</p>
									<p>
										<div style={{ width: '24px' }}>
											<Avatar
												size={PersonaSize.size32}
												name={agendaItem.userMail}
												mail={agendaItem.userMail}
												index={index}
											/>
										</div>
									</p>
									<p>{isNow && <div className={styles.now}>{t('Now')}</div>}</p>
								</div>
								<div className={styles.flex}>
									<div
										onClick={(e) => {
											handleAgendaItemClickOption(agendaItem);
											e.stopPropagation();
										}}>
										<IconDelete />
									</div>
									<div>
										<IconDrop />
									</div>
								</div>
							</div>
						</div>
					);
				})}

			{openConfirmBox && agendaItemToDelete && (
				<DeleteConfirmBox
					handleDelete={handleDeleteAgendaItem}
					handleConfirmBox={handleOpenConfirmBox}
					message='Are you sure you want to delete this agenda item?'
				/>
			)}

			{openUpdate && agendaItemToUpdate && (
				<MeetingsDetailsAgendaUpdate agenda={agendaItemToUpdate} close={handleOpenUpdate} />
			)}
		</div>
	);
};

export default MeetingsDetailsAgendaTable;
