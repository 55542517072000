import React from 'react';
import styles from './view-switcher.module.scss';
import { IconClock, IconTodoCalendarView, IconTodoKaban, IconTodoListView } from '../../utils/icon';

interface Props {
    handleGroupChange: (view: string) => void,
    view: string,
}

const ViewSwitcher: React.FC<Props> = ({ handleGroupChange, view }) => {
    const sliderPosition = () => {
		switch(view) {
			case 'Simple':
				return 0;
			case 'Board':
				return 25;
			case 'List':
				return 50;
			case 'Meetings':
				return 75;
		}
	};
    

    return (
        <div className={styles.icons}>
            <div className={styles.slider} style={{ left: `${sliderPosition()}%` }}/>
            <div
                className={view === 'Simple' ? styles.iconSelect : styles.icon}
                onClick={() => {
                    handleGroupChange('Simple');
                }}>
                <IconClock />
            </div>
            <div
                className={view === 'Board' ? styles.iconSelect : styles.icon}
                onClick={() => {
                    handleGroupChange('Board');
                }}>
                <IconTodoKaban />
            </div>
            <div
                className={view === 'List' ? styles.iconSelect : styles.icon}
                onClick={() => {
                    handleGroupChange('List');
                }}>
                <div className={styles.childIcon}>
                    <IconTodoCalendarView />
                </div>
            </div>
            <div
                className={view === 'Meetings' ? styles.iconSelect : styles.icon}
                onClick={() => {
                    handleGroupChange('Meetings');
                }}>
                <div className={styles.childIcon}>
                    <IconTodoListView />
                </div>
            </div>
        </div>
    );
};

export default ViewSwitcher; 