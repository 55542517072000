/* eslint-disable unicorn/prefer-ternary */
/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { RequestStatusType } from '../../../../../../store/shared/types';
import Loader from '../../../../../../shared/components/loader/loader.component';
import MeetingsDetailsTodosFormComponents from './meetings-details-todos-form.components';
import styles from './meetings-details-todos.module.scss';
import {
	IconClock,
	IconDateTime,
	IconList,
	IconMeetings,
	IconSimplePlus,
	IconTodoCalendarView,
	IconTodoListView,
} from '../../../../../../shared/utils/icon';
import { useTranslation } from 'react-i18next';
import TodosViewList from '../../../../../todos/components/todos-view-list';
import TodosViewMeetings from '../../../../../todos/components/todos-view-meetings';
import TodosViewSimple from '../../../../../todos/components/todos-view-simple';
import {
	getSelectMeetingTodos,
	getSelectMeetingTodosPrev,
	setPrevMeetingsTodos,
} from '../../../../../../store/todos/slice';
import TodosFilterModal from '../../../../../todos/modals/todos.filter';
import { TodosContract, TodosStates } from '../../../../../../store/todos/type';
import FilterBtn from '../../../../../../shared/components/buttons/filterBtn/filter-btn.component';
import OutlinedButton from '../../../../../../shared/components/buttons/outlinedBtn/outlined-btn.component';
import {
	getSelectMeetingTodosForShared,
	getSelectMeetingTodosForSharedPrev,
} from '../../../../../../store/sharing/slice';

interface Props {
	storedAccessMail?: string;
}

interface Meeting {
	meetingName: string;
	meetingStartDate: string;
	meetingGraphId: string;
	graphiCalUId: string;
}

const MeetingsDetailsTodos: React.FC<Props> = ({ storedAccessMail }) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { user } = useAppSelector((state) => state.user);
	const { selectMeetingTodos, selectMeetingTodosRequestStatus, prevMeetingsTodos } = useAppSelector(
		(state) => state.todos,
	);
	const { shareTodos, shareTodosRequestStatus } = useAppSelector((state) => state.sharing);
	const { prevMeeting, selectedMeeting, prevMeetings } = useAppSelector((state) => state.meetings);

	const storedView = localStorage.getItem('viewTodoMeetings');
	const [view, setView] = React.useState(storedView ? storedView : 'Simple');

	React.useEffect(() => {
		const fetchData = async () => {
			if (selectedMeeting) {
				if (user) {
					await dispatch(getSelectMeetingTodos(selectedMeeting.iCalUId));
				} else {
					await dispatch(getSelectMeetingTodosForShared(selectedMeeting.iCalUId));
				}
			}
		};
		fetchData();
	}, [selectedMeeting]);

	React.useEffect(() => {
		if (prevMeetings) {
			if (user) {
				const todosPromises = prevMeetings.map((m) =>
					dispatch(getSelectMeetingTodosPrev(m.iCalUId)).then((res) => res.payload as TodosContract[]),
				);

				Promise.all(todosPromises).then((results) => {
					const allTodos = results.flat();
					if (allTodos.length > 0) {
						dispatch(setPrevMeetingsTodos(allTodos));
					}
				});
			} else {
				const todosPromises = prevMeetings.map((m) =>
					dispatch(getSelectMeetingTodosForSharedPrev(m.iCalUId)).then(
						(res) => res.payload as TodosContract[],
					),
				);

				Promise.all(todosPromises).then((results) => {
					const allTodos = results.flat();
					if (allTodos.length > 0) {
						dispatch(setPrevMeetingsTodos(allTodos));
					}
				});
			}
		}
	}, [selectedMeeting, prevMeetings, user, storedAccessMail, dispatch]);

	const [meetings, setMeetings] = React.useState<Meeting[]>(() => {
		const selectedMeetingData = {
			meetingName: selectedMeeting ? selectedMeeting.subject : '',
			meetingStartDate: selectedMeeting ? selectedMeeting.start : '',
			meetingGraphId: selectedMeeting ? selectedMeeting.graphEventId : '',
			graphiCalUId: selectedMeeting ? selectedMeeting.iCalUId : '',
		};

		const prevMeetingsData = prevMeetings
			? [...(prevMeetings as any)]
				.sort((a, b) => new Date(b.start).getTime() - new Date(a.start).getTime())
				.map((m) => ({
					meetingName: m.subject || m.meetingName,
					meetingStartDate: m.start || m.meetingStartDate,
					meetingGraphId: m.graphEventId || m.meetingGraphId,
					graphiCalUId: m.iCalUId || m.graphiCalUId,
				}))
			: [];

		return [selectedMeetingData, ...prevMeetingsData];
	});

	const handleGroupChange = (val: string) => {
		localStorage.setItem('viewTodoMeetings', val);
		setView(val);
	};

	const [menu, setMenu] = React.useState(false);
	const handleMenu = () => {
		setMenu(!menu);
	};

	const modalRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		setMeetings(() => {
			const selectedMeetingData = {
				meetingName: selectedMeeting ? selectedMeeting.subject : '',
				meetingStartDate: selectedMeeting ? selectedMeeting.start : '',
				meetingGraphId: selectedMeeting ? selectedMeeting.graphEventId : '',
				graphiCalUId: selectedMeeting ? selectedMeeting.iCalUId : '',
			};

			const prevMeetingsData = prevMeetings
				? [...(prevMeetings as any)]
					.sort((a, b) => new Date(b.start).getTime() - new Date(a.start).getTime())
					.map((m) => ({
						meetingName: m.subject || m.meetingName,
						meetingStartDate: m.start || m.meetingStartDate,
						meetingGraphId: m.graphEventId || m.meetingGraphId,
						graphiCalUId: m.iCalUId || m.graphiCalUId,
					}))
				: [];

			return [selectedMeetingData, ...prevMeetingsData];
		});
	}, [selectedMeeting]);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleMenu();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleMenu]);

	const [showTodo, setShowTodo] = React.useState<boolean>(true);
	const [showDone, setShowDone] = React.useState<boolean>(true);
	const [showArchived, setArchived] = React.useState<boolean>(false);
	const [showDoing, setShowDoing] = React.useState<boolean>(true);
	const [showBlock, setShowBlock] = React.useState<boolean>(true);

	const handleShowTodo = (show: boolean) => {
		setShowTodo(show);
	};

	const handleShowDone = (show: boolean) => {
		setShowDone(show);
	};

	const handleShowArchived = (show: boolean) => {
		setArchived(show);
	};

	const handleShowDoing = (show: boolean) => {
		setShowDoing(show);
	};

	const handleShowBlock = (show: boolean) => {
		setShowBlock(show);
	};

	const [showFilterModal, setShowFilterModal] = React.useState(false);
	const handleShowFilterModal = () => {
		setShowFilterModal(!showFilterModal);
	};

	const modalRefFilter = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRefFilter.current && !modalRefFilter.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleShowFilterModal();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleShowFilterModal]);

	const trueCount = [showTodo, showDone, showArchived, showDoing, showBlock].filter(Boolean).length;

	return (
		<>
			{user ? (
				<div>
					<div className={styles.over}>
						<div className={styles.top}>
							<div className={styles.flexButton}>
								<OutlinedButton onClick={() => handleMenu()}>
									<IconSimplePlus /> {t('Add new todo')}
								</OutlinedButton>
								<div className={styles.filters}>
									<div className={styles.place}>
										<FilterBtn
											showFilterModal={showFilterModal}
											trueCount={trueCount}
											onClick={() => handleShowFilterModal()}
										/>
										{showFilterModal && (
											<div className={styles.filterModalWrapper} ref={modalRefFilter}>
												<TodosFilterModal
													showTodo={showTodo}
													showDone={showDone}
													showArchived={showArchived}
													showDoing={showDoing}
													showBlock={showBlock}
													handleShowTodo={handleShowTodo}
													handleShowDone={handleShowDone}
													handleShowArchived={handleShowArchived}
													handleShowDoing={handleShowDoing}
													handleShowBlock={handleShowBlock}
												/>
											</div>
										)}
									</div>
								</div>
							</div>
							<div className={styles.flex}>
								<div className={styles.icons}>
									<div
										className={view === 'Simple' ? styles.iconSelect : styles.icon}
										onClick={() => {
											handleGroupChange('Simple');
										}}>
										<IconClock />
									</div>
									<div
										className={view === 'List' ? styles.iconSelect : styles.icon}
										onClick={() => {
											handleGroupChange('List');
										}}>
										<div className={styles.childIcon}>
											<IconTodoCalendarView />
										</div>
									</div>
									<div
										className={view === 'Meetings' ? styles.iconSelect : styles.icon}
										onClick={() => {
											handleGroupChange('Meetings');
										}}>
										<div className={styles.childIcon}>
											<IconTodoListView />
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className={styles.overview}>
							{view === 'List' && selectMeetingTodos && (
								<>
									<div className={styles.mainTodoPage}>
										<TodosViewList
											attendees={selectedMeeting?.attendees.map((attendee) => ({ name: attendee.emailAddress.name, email: attendee.emailAddress.address }))}
											todosAssignee={
												prevMeeting && prevMeetingsTodos
													? [
														...selectMeetingTodos.filter((todo) => {
															if (todo.archived && !showArchived) {
																return false;
															}

															if (showTodo && todo.state === TodosStates.TODO) {
																return true;
															}

															if (showDoing && todo.state === TodosStates.DOING) {
																return true;
															}

															if (showDone && todo.state === TodosStates.DONE) {
																return true;
															}

															if (showBlock && todo.state === TodosStates.BLOCKED) {
																return true;
															}

															return false;
														}),
														...prevMeetingsTodos.filter((todo) => {
															if (todo.archived && !showArchived) {
																return false;
															}

															if (showTodo && todo.state === TodosStates.TODO) {
																return true;
															}

															if (showDoing && todo.state === TodosStates.DOING) {
																return true;
															}

															if (showDone && todo.state === TodosStates.DONE) {
																return true;
															}

															if (showBlock && todo.state === TodosStates.BLOCKED) {
																return true;
															}

															return false;
														}),
													]
													: selectMeetingTodos.filter((todo) => {
														if (todo.archived && !showArchived) {
															return false;
														}

														if (showTodo && todo.state === TodosStates.TODO) {
															return true;
														}

														if (showDoing && todo.state === TodosStates.DOING) {
															return true;
														}

														if (showDone && todo.state === TodosStates.DONE) {
															return true;
														}

														if (showBlock && todo.state === TodosStates.BLOCKED) {
															return true;
														}

														return false;
													})
											}

										/>
									</div>
								</>
							)}

							{view === 'Meetings' && selectMeetingTodos && meetings && (
								<>
									<div className={styles.mainTodoPage}>
										<TodosViewMeetings
											attendees={selectedMeeting?.attendees.map((attendee) => ({ name: attendee.emailAddress.name, email: attendee.emailAddress.address }))}
											todosAssignee={
												prevMeetings && prevMeetingsTodos
													? [
														...selectMeetingTodos.filter((todo) => {
															if (todo.archived && !showArchived) {
																return false;
															}

															if (showTodo && todo.state === TodosStates.TODO) {
																return true;
															}

															if (showDoing && todo.state === TodosStates.DOING) {
																return true;
															}

															if (showDone && todo.state === TodosStates.DONE) {
																return true;
															}

															if (showBlock && todo.state === TodosStates.BLOCKED) {
																return true;
															}

															return false;
														}),
														...prevMeetingsTodos.filter((todo) => {
															if (todo.archived && !showArchived) {
																return false;
															}

															if (showTodo && todo.state === TodosStates.TODO) {
																return true;
															}

															if (showDoing && todo.state === TodosStates.DOING) {
																return true;
															}

															if (showDone && todo.state === TodosStates.DONE) {
																return true;
															}

															if (showBlock && todo.state === TodosStates.BLOCKED) {
																return true;
															}

															return false;
														}),
													]
													: selectMeetingTodos.filter((todo) => {
														if (todo.archived && !showArchived) {
															return false;
														}

														if (showTodo && todo.state === TodosStates.TODO) {
															return true;
														}

														if (showDoing && todo.state === TodosStates.DOING) {
															return true;
														}

														if (showDone && todo.state === TodosStates.DONE) {
															return true;
														}

														if (showBlock && todo.state === TodosStates.BLOCKED) {
															return true;
														}

														return false;
													})
											}
											meetings={meetings.filter((meeting) => meeting.graphiCalUId !== 'none')}
										/>
									</div>
								</>
							)}

							{view === 'Simple' && selectMeetingTodos && (
								<>
									<div className={styles.mainTodoPage}>
										<TodosViewSimple
											attendees={selectedMeeting?.attendees.map((attendee) => ({ name: attendee.emailAddress.name, email: attendee.emailAddress.address }))}
											todosAssignee={
												prevMeeting && prevMeetingsTodos
													? [
														...selectMeetingTodos.filter((todo) => {
															if (todo.archived && !showArchived) {
																return false;
															}

															if (showTodo && todo.state === TodosStates.TODO) {
																return true;
															}

															if (showDoing && todo.state === TodosStates.DOING) {
																return true;
															}

															if (showDone && todo.state === TodosStates.DONE) {
																return true;
															}

															if (showBlock && todo.state === TodosStates.BLOCKED) {
																return true;
															}

															return false;
														}),
														...prevMeetingsTodos.filter((todo) => {
															if (todo.archived && !showArchived) {
																return false;
															}

															if (showTodo && todo.state === TodosStates.TODO) {
																return true;
															}

															if (showDoing && todo.state === TodosStates.DOING) {
																return true;
															}

															if (showDone && todo.state === TodosStates.DONE) {
																return true;
															}

															if (showBlock && todo.state === TodosStates.BLOCKED) {
																return true;
															}

															return false;
														}),
													]
													: selectMeetingTodos.filter((todo) => {
														if (todo.archived && !showArchived) {
															return false;
														}

														if (showTodo && todo.state === TodosStates.TODO) {
															return true;
														}

														if (showDoing && todo.state === TodosStates.DOING) {
															return true;
														}

														if (showDone && todo.state === TodosStates.DONE) {
															return true;
														}

														if (showBlock && todo.state === TodosStates.BLOCKED) {
															return true;
														}

														return false;
													})
											}
										/>
									</div>
								</>
							)}
						</div>
						{menu && (
							<div ref={modalRef}>
								<MeetingsDetailsTodosFormComponents close={handleMenu} />
							</div>
						)}
					</div>
					{selectMeetingTodosRequestStatus.type === RequestStatusType.Failed && (
						<div>{selectMeetingTodosRequestStatus.errorMessage}</div>
					)}
				</div>
			) : (
				<div className={styles.over}>
					{shareTodosRequestStatus.type === RequestStatusType.InProgress && (
						<div>
							<Loader></Loader>
						</div>
					)}

					{shareTodosRequestStatus.type === RequestStatusType.Success && (
						<div className={styles.over}>
							<div className={styles.top}>
								<button className={styles.cancel} onClick={() => handleMenu()}>
									<IconSimplePlus />
									{t('Add new todo')}
								</button>
								<div className={styles.flex}>
									<div className={styles.icons}>
										<div
											className={view === 'Simple' ? styles.iconSelect : styles.icon}
											onClick={() => {
												handleGroupChange('Simple');
											}}>
											<IconList />
										</div>
										<div
											className={view === 'List' ? styles.iconSelect : styles.icon}
											onClick={() => {
												handleGroupChange('List');
											}}>
											<div className={styles.childIcon}>
												<IconDateTime />
											</div>
										</div>
										<div
											className={view === 'Meetings' ? styles.iconSelect : styles.icon}
											onClick={() => {
												handleGroupChange('Meetings');
											}}>
											<div className={styles.childIcon}>
												<IconMeetings />
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className={styles.overview}>
								{view === 'List' && shareTodos && (
									<>
										<div className={styles.mainTodoPage}>
											<TodosViewList
												todosAssignee={
													prevMeetings && prevMeetingsTodos
														? [...shareTodos, ...prevMeetingsTodos]
														: shareTodos
												}
											/>
										</div>
									</>
								)}

								{view === 'Meetings' && shareTodos && meetings && (
									<>
										<div className={styles.mainTodoPage}>
											<TodosViewMeetings
												todosAssignee={
													prevMeetings && prevMeetingsTodos
														? [...shareTodos, ...prevMeetingsTodos]
														: shareTodos
												}
												meetings={meetings}
											/>
										</div>
									</>
								)}

								{view === 'Simple' && shareTodos && (
									<>
										<div className={styles.mainTodoPage}>
											<TodosViewSimple
												todosAssignee={
													prevMeetings && prevMeetingsTodos
														? [...shareTodos, ...prevMeetingsTodos]
														: shareTodos
												}
											/>
										</div>
									</>
								)}
							</div>
							{menu && (
								<div ref={modalRef}>
									<MeetingsDetailsTodosFormComponents
										close={handleMenu}
										storedAccessMail={storedAccessMail}
									/>
								</div>
							)}
						</div>
					)}

					{shareTodosRequestStatus.type === RequestStatusType.Failed && (
						<div>{shareTodosRequestStatus.errorMessage}</div>
					)}
				</div>
			)}
		</>
	);
};

export default MeetingsDetailsTodos;
