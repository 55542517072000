import { AccessRightType, TagContract } from '../meetings/types';
import { TodosStates } from '../todos/type';

export class ProjectContract {
	constructor(
		public id: string,
		public projectName: string,
		public accessRightType: AccessRightType,
		public graphUserId: string,
		public text: string,
		public tags: TagContract[],
		public emoticon: string,
		public archived: boolean,
		public todoCol: string,
		public doingCol: string,
		public blockedCol: string,
		public doneCol: string,
		public createdOn: Date,
		public countNotes?: number,
		public countMeetings?: number,
		public countTodos?: number,
		public countFiles?: number,
	) {}
}

export class MeetingsProjectContract {
	constructor(
		public id: string,
		public projectId: string,
		public graphiCalUId: string,
		public meetingName: string,
		public meetingStartDate: string,
		public meetingEndDate: string,
		public meetingAttendees: string[],
		public tags: TagContract[],
	) {}
}

export interface UpdateStatePayload {
	id: string;
	newText: string;
}

export interface UpdateStateArchivePayload {
	id: string;
	archive: boolean;
}

export interface UpdateStateTodoPayload {
	todoId: string;
	stateTodo: TodosStates;
	indexProject?: number;
	projectCol?: string | null;
}

export enum NotificationsProjectType {
	NEW_TODO = 'NEW_TODO',
	TODO_ASSIGNED = 'TODO_ASSIGNED',
	TODO_COMPLETE = 'TODO_COMPLETE',
	NEW_NOTE = 'NEW_NOTE',
	NEW_MEETING = 'NEW_MEETING',
	NEW_FILE = 'NEW_FILE',
	NEW_MENTION = 'NEW_MENTION',
	NEW_MENTION_COMMENT = 'NEW_MENTION_COMMENT',
}

export class NotificationProjectContract {
	constructor(
		public id: string,
		public userFromName: string,
		public userFromMail: string,
		public type: NotificationsProjectType,
		public noteId: string,
		public todoId: string,
		public todoDueDate: string,
		public itemTitle: string,
		public graphiCalUId: string,
		public meetingStartDate: string,
		public meetingEndDate: string,
		public meetingName: string,
		public meetingAttendees: string[],
		public projectId: string,
		public userSeenNotif: string[],
		public todoAssignee: string,
	) {}
}

export interface UpdateSeeNotification {
	notificationId: string;
	user: string;
}

export class UpdateProjectCount {
	constructor(
		public projectId: string,
		public noteCount: number,
		public meetingsCount: number,
		public todoCount: number,
		public filesCount: number,
	) {}
}
