/* eslint-disable unicorn/prefer-ternary */
import React from 'react';
import MeetingsDetailsNotesBridge from './meetings-details-notes-bridge.component';
import styles from './meetings-details-notes.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
	getAllNoteForAMeeting,
	getAllNoteForAPrecedentMeeting,
	setPrevMeetingsNotes,
} from '../../../../../../store/notes/slice';
import { getAllNoteForAMeetingShared, getAllNoteForAMeetingSharedPrev } from '../../../../../../store/sharing/slice';
import { MeetingContract } from '../../../../../../store/meetings/types';
import { NotesContract } from '../../../../../../store/notes/type';
import { MeetingForSeriesContract } from '../../../../../../store/series/type';

interface Props {
	storedAccessMail?: string;
}

const MeetingsDetailsNotes: React.FC<Props> = ({ storedAccessMail }) => {
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.user);
	const { selectedMeeting } = useAppSelector((state) => state.meetings);

	const { prevMeetings, prevMeeting } = useAppSelector((state) => state.meetings);
	const [notes, setNotes] = React.useState<NotesContract[]>([]);

	React.useEffect(() => {
		const fetchData = async () => {
			if (selectedMeeting) {
				if (user) {
					await dispatch(getAllNoteForAMeeting(selectedMeeting.iCalUId));
				} else {
					await dispatch(
						getAllNoteForAMeetingShared({
							iCalUId: selectedMeeting.iCalUId,
							userId: storedAccessMail ? storedAccessMail : '',
						}),
					);
				}
			}
		};
		fetchData();
	}, [selectedMeeting]);

	const fetchNotes = async (meeting: MeetingContract | MeetingForSeriesContract) => {
		try {
			setNotes([]);
			const res = await dispatch(getAllNoteForAPrecedentMeeting(meeting.iCalUId));
			const note = res.payload as NotesContract;
			setNotes((prevNotes) => [...prevNotes, note]);
			return note;
		} catch (error) {
			console.error('Error fetching notes:', error);
			throw error;
		}
	};

	const fetchNotesInvite = async (meeting: MeetingContract | MeetingForSeriesContract, mail: string) => {
		try {
			setNotes([]);
			const res = await dispatch(getAllNoteForAMeetingSharedPrev({ iCalUId: meeting.iCalUId, userId: mail }));
			const note = res.payload as NotesContract;
			setNotes((prevNotes) => [...prevNotes, note]);
			return note;
		} catch (error) {
			console.error('Error fetching notes:', error);
			throw error;
		}
	};

	React.useEffect(() => {
		dispatch(setPrevMeetingsNotes(undefined));
		if (selectedMeeting && prevMeetings) {
			if (prevMeetings.length > 0) {
				if (user) {
					const fetchNotesPromises = prevMeetings.map(async (meeting) => {
						return fetchNotes(meeting);
					});

					Promise.all(fetchNotesPromises)
						.then((resolvedNotes) => {
							const flatNotes = resolvedNotes.flat();

							if (flatNotes.length > 0) {
								dispatch(setPrevMeetingsNotes(flatNotes));
							} else {
								dispatch(setPrevMeetingsNotes(undefined));
							}
						})
						.catch((error) => {
							console.error('Error fetching notes:', error);
						});
				} else if (storedAccessMail) {
					const fetchNotesPromises = prevMeetings.map(async (meeting) => {
						return fetchNotesInvite(meeting, storedAccessMail);
					});

					Promise.all(fetchNotesPromises)
						.then((resolvedNotes) => {
							const flatNotes = resolvedNotes.flat();

							if (flatNotes.length > 0) {
								dispatch(setPrevMeetingsNotes(flatNotes));
							} else {
								dispatch(setPrevMeetingsNotes(undefined));
							}
						})
						.catch((error) => {
							console.error('Error fetching notes:', error);
						});
				}
			} else {
				dispatch(setPrevMeetingsNotes(undefined));
			}
		} else {
			dispatch(setPrevMeetingsNotes(undefined));
		}
	}, [prevMeetings, selectedMeeting]);

	return (
		<div className={styles.over}>
			<MeetingsDetailsNotesBridge storedAccessMail={storedAccessMail} />
		</div>
	);
};

export default MeetingsDetailsNotes;
