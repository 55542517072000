import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import styles from './meeting-details-form.module.scss';
import { MeetingsProjectContract, ProjectContract } from '../../../../../../../store/project/type';
import { getDateFormatL } from '../../../../../../../shared/utils/date.util';
import { linkMeetingProject } from '../../../../../../../store/project/slice';
import { MeetingContract } from '../../../../../../../store/meetings/types';
import { updateSelectedMeeting, updateSelectedMeetingForMeetings } from '../../../../../../../store/meetings/slice';
import { IconClose, IconLink } from '../../../../../../../shared/utils/icon';
import { toast } from '../../../../../../../shared/components/modals/toast/toast-manager';
import Input, { Items } from '../../../../../../../shared/components/input/input.component';

export interface MeetingDetailsHeaderProps {
	handleClose: () => void;
}

const MeetingDetailsProjectConnect = ({ handleClose }: MeetingDetailsHeaderProps) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.user);
	const { selectedMeeting } = useAppSelector((state) => state.meetings);
	const { projects } = useAppSelector((state) => state.project);
	const modalRef = React.useRef<HTMLDivElement>(null);
	const [animation, setAnimation] = useState<boolean>(false);

	const closeModal = () => {
		setAnimation(true);
		setTimeout(() => {
			handleClose();
			setAnimation(false);
		}, 300);
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleClose();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleClose]);

	type FormData = {
		project: ProjectContract;
	};

	const dataSchema = yup.object().shape({
		project: yup.object(),
	});

	const { handleSubmit, control, setValue } = useForm<FormData>({
		resolver: yupResolver(dataSchema),
	});

	const handleChangeProject = (item: Items | undefined) => {
		const selectedProject = projects?.find((project) => project.id === item?.id);

		if (selectedProject) {
			setValue('project', selectedProject);
			return;
		}

		setValue('project', {} as ProjectContract);
	};

	const onSubmit = (data: FormData) => {
		if (data.project && user && selectedMeeting) {
			const newMeeting = selectedMeeting as MeetingContract;
			const attendeeEmails = newMeeting
				? newMeeting.attendees.map((attendee) => attendee.emailAddress.address.toLowerCase())
				: [];

			const bodyMeetingProject: MeetingsProjectContract = {
				id: '',
				projectId: data.project.id,
				graphiCalUId: newMeeting.iCalUId,
				meetingName: newMeeting.subject,
				meetingStartDate: newMeeting.start,
				meetingEndDate: newMeeting.end,
				meetingAttendees: attendeeEmails,
				tags: [],
			};
			dispatch(linkMeetingProject({ body: bodyMeetingProject, projectId: data.project.id })).then((res) => {
				const val = {
					subject: selectedMeeting.subject,
					start: selectedMeeting.start,
					end: selectedMeeting.end,
					location: selectedMeeting.location,
					attendees: selectedMeeting.attendees,
					group: selectedMeeting.group,
					isOnlineMeeting: selectedMeeting.isOnlineMeeting,
					onlineMeetingProvider: 'teamsForBusiness',
					bodyContent: selectedMeeting.bodyContent,
					isAllDay: selectedMeeting.isAllDay,
					projectId: data.project
						? data.project.id
						: selectedMeeting.projectId
						? selectedMeeting.projectId
						: '',
					IcalUid: selectedMeeting.iCalUId,
				};

				if (res.meta.requestStatus === 'fulfilled') {
					dispatch(updateSelectedMeeting(val));
					dispatch(updateSelectedMeetingForMeetings(val));
					toast.show({
						id: user?.graphUserId,
						title: t('Project connect success') as string,
						duration: 10000,
						type: 'success',
					});
				}

				closeModal();
			});
		}
	};

	return (
		<div className={`${styles.container} ${animation ? styles.fadeOut : ''}`}>
			<div className={`${styles.modal} ${animation ? styles.slideOut : ''}`}>
				<div className={styles.titleWrapper}>
					<h3>
						<IconLink />
						{t('Connected project')}
					</h3>
					<div className={styles.closeButton}>
						<div
							className={styles.icon}
							onClick={() => {
								closeModal();
							}}>
							<IconClose />
						</div>
					</div>
				</div>
				<form onSubmit={handleSubmit(onSubmit)}>
					{user && projects && selectedMeeting && (
						<Input
							placeHolder={t<string>('Project')}
							getValue={handleChangeProject}
							defaultValue={{
								title:
									projects.find((project) => project.id === selectedMeeting.projectId)?.projectName ??
									'',
							}}
							renderModal={projects.map((project) => ({
								id: project.id,
								title: `${project.projectName} (${getDateFormatL(project.createdOn)})`,
							}))}
						/>
					)}

					<div className={styles.btnContainer}>
						<button className={styles.addBtn}>{t('Save')}</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default MeetingDetailsProjectConnect;
