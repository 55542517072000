/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable unicorn/no-null */
import React from 'react';
import { TodosStates } from '../../../store/todos/type';
import styles from './todo-board.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addTodosAssignee, createTodos } from '../../../store/todos/slice';
import { IconMeetings, IconSearch } from '../../../shared/utils/icon';
import TodoDateSelect from './date-select';
import { getDateFormatWithYear } from '../../../shared/utils/date.util';
import { Avatar } from '../../../shared/utils/avatar';
import startOfDay from 'date-fns/startOfDay';
import { getCurrentUserContacts } from '../../../store/user/slice';
import { ContactsGraphContract } from '../../../store/user/type';
import { NotificationsType } from '../../../store/notifications/type';
import { SendNotifications } from '../../../store/notifications/slice';
import { PersonaSize } from '@fluentui/react';
import { getTodosProject, updateProjectCountTodosAdd } from '../../../store/project/slice';
import { RouteUrls } from '../../../routes/routes-config';

interface Props {
	close: () => void;
	todoState: TodosStates;
	projectId?: string;
}

const TodosViewBoardFormTodo: React.FC<Props> = ({ close, todoState, projectId }) => {
	const { t } = useTranslation();

	const dispatch = useAppDispatch();
	const { contacts, user } = useAppSelector((state) => state.user);

	const currentPath = window.location.pathname;
	const TodoPagePath = RouteUrls.Todos;

	const renderTodos = currentPath === TodoPagePath;

	const [datePicker, setDatePicker] = React.useState(false);

	const handleDatePicker = () => {
		setDatePicker(!datePicker);
	};

	const [date, setDate] = React.useState<Date>(new Date());
	const [isDate, setIsDate] = React.useState(false);

	const handleDateSelect = (dateSelectPicker: Date) => {
		setIsDate(true);
		setDate(startOfDay(dateSelectPicker));
		handleDatePicker();
	};

	const modalRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					close();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [close]);

	const [title, setTitle] = React.useState('');

	const [assignee, setAssignee] = React.useState<{ mail: string; name: string }>(
		user ? { mail: user.email, name: user.displayName } : { mail: '', name: '' },
	);

	const [noMatch, setNotMatch] = React.useState(false);
	const [contactNM, setContactNM] = React.useState<{ mail: string; name: string }>();

	// ---

	const createTodo = () => {
		if (user) {
			const body = {
				id: undefined,
				title: title !== '' ? title : 'New todo',
				text: undefined,
				state: todoState,
				dueDate: isDate ? new Date(date).toUTCString() : undefined,
				duration: undefined,
				graphUserId: user.graphUserId,
				noteId: undefined,
				assigneeDisplayName: assignee.mail,
				graphiCalUId: undefined,
				meetingGraphId: undefined,
				meetingName: undefined,
				meetingStartDate: undefined,
				createdOn: new Date(),
				tags: undefined,
				assigneeName: assignee.name,
				archived: false,
				projectId: projectId ? projectId : undefined,
				columnUserId: null,
				columnProjectId: null,
			};

			if (projectId) {
				dispatch(updateProjectCountTodosAdd(projectId));
			}

			dispatch(createTodos(body)).then((res) => {
				const todo = res.payload as any;
				if (user) {
					const newTodo = {
						id: todo.id,
						title: todo.title,
						text: '',
						state: todoState,
						dueDate: isDate ? new Date(date).toUTCString() : null,
						duration: 0,
						graphUserId: user.graphUserId,
						noteId: null,
						assigneeDisplayName: todo.assigneeDisplayName,
						graphiCalUId: null,
						meetingGraphId: null,
						meetingName: null,
						meetingStartDate: null,
						createdOn: new Date().toISOString(),
						tags: [],
						assigneeName: todo.assigneeName,
						archived: todo.archived,
						columnUserId: null,
						columnProjectId: null,
					};

					if (projectId) {
						dispatch(getTodosProject(projectId));
					}
					dispatch(addTodosAssignee(newTodo));
					setTitle('');
					setIsDate(false);
					setDate(new Date());

					if (todo.assigneeDisplayName !== user.userName) {
						const bodyNotification: any = {
							id: undefined,
							userFromName: user.name,
							userFromMail: user.userName,
							userToName: todo.assigneeName,
							userToMail: todo.assigneeDisplayName,
							type: NotificationsType.NEW_TODO,
							noteId: undefined,
							todoId: todo.id,
							graphiCalUId: undefined,
							meetingStartDate: undefined,
							itemTitle: todo.title,
							visible: true,
							meetingName: undefined,
						};

						dispatch(SendNotifications(bodyNotification));
					}

					close();
				}
			});
		}
	};

	// ---

	let timeoutId: any;

	const [isShearching, setIsShearching] = React.useState('');

	const onChangeHandler = (filter: string) => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(function () {
			setIsShearching(filter);

			dispatch(getCurrentUserContacts(filter)).then((res) => {
				const response = res.payload as ContactsGraphContract[];
				setNotMatch(false);

				if (response?.length < 1) {
					setNotMatch(true);
					setContactNM({ mail: filter, name: filter });
				}
			});
		}, 200);
	};

	const isEmail = (email: string) => {
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailPattern.test(email);
	};

	const modalRefAttendees = React.useRef<HTMLDivElement>(null);

	const [attendeeMenu, setAttendeeMenu] = React.useState(false);

	const handleMenu = () => {
		setAttendeeMenu(!attendeeMenu);
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRefAttendees.current && !modalRefAttendees.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleMenu();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleMenu]);

	// ___

	return (
		<div className={styles.addTaskForm} ref={modalRef}>
			<h2>{t('Add new todo')}</h2>
			<div>
				<input
					placeholder={t('Write the title of your todo') as string}
					type='text'
					defaultValue={title}
					onChange={(e) => setTitle(e.target.value)}
				/>
			</div>
			<div className={styles.timeslot}>
				<div className={styles.startDate}>
					<div>
						<span className={styles.titleDate}>{t('Due date')}</span>
						<br />

						<div className={styles.datePickerTime}>
							<span className={styles.dueDate}>
								{isDate ? getDateFormatWithYear(date.toString()) : t('No due date')}
							</span>
						</div>
					</div>

					<div className={styles.icon} onClick={() => handleDatePicker()}>
						<IconMeetings />
					</div>

					{datePicker && date && (
						<TodoDateSelect date={date} handleDatePicker={handleDateSelect} close={handleDatePicker} />
					)}
				</div>
			</div>
			<div className={styles.assignee} onClick={() => handleMenu()}>
				<div className={styles.flexAvatar}>
					<Avatar size={PersonaSize.size28} name={assignee.name} mail={assignee.mail} index={8} />
					<p className={styles.nameAt}>{assignee.name}</p>
				</div>
			</div>
			{attendeeMenu && (
				<div className={styles.modalAttendee} ref={modalRefAttendees}>
					{user && (
						<div className={styles.searchInput}>
							<span>
								<IconSearch />
							</span>
							<input type='text' onChange={(e) => onChangeHandler(e.target.value)} />
						</div>
					)}
					<div className={styles.containerList}>
						{!renderTodos && (
							<div
								className={styles.list}
								onClick={() => {
									setAssignee({
										mail: '',
										name: 'Unasigned',
									});
									handleMenu();
									setIsShearching('');
								}}>
								<div className={styles.name}>
									<Avatar name={'Unasigned'} mail={''} index={2} />
									<span>{t('Unasigned')}</span>
								</div>
							</div>
						)}

						{user &&
							user?.userCompany
								.filter((userC) => userC.userName.toLowerCase().includes(isShearching.toLowerCase()))
								.map((userC, index) => (
									<div
										className={styles.list}
										key={index}
										onClick={() => {
											setAssignee({
												mail: userC.userName,
												name: userC.name,
											});
											handleMenu();
											setIsShearching('');
										}}>
										<div className={styles.name}>
											<Avatar name={userC.name} mail={userC.userName} index={index} />
											<span>{userC.name}</span>
										</div>
									</div>
								))}
						{user &&
							contacts &&
							contacts
								.filter(
									(contact) =>
										contact.emailAddresses &&
										contact.emailAddresses.length > 0 &&
										contact.emailAddresses[0].address !== '' &&
										contact.displayName !== '',
								)
								.map((contact, index) => (
									<div
										className={styles.list}
										key={index}
										onClick={() => {
											setAssignee({
												mail: contact.emailAddresses[0].address,
												name: contact.displayName,
											});
											handleMenu();
										}}>
										<div className={styles.name}>
											<Avatar
												name={contact.displayName}
												mail={contact.emailAddresses[0].address}
												index={index}
											/>
											<span>{contact.displayName}</span>
										</div>
									</div>
								))}
						{user && noMatch && contactNM && isEmail(contactNM.mail) === true && (
							<div
								className={styles.list}
								onClick={() => {
									setAssignee({
										mail: contactNM.mail,
										name: contactNM.name,
									});
									handleMenu();
								}}>
								<div className={styles.name}>
									<Avatar name={contactNM.name} mail={contactNM.mail} index={1} />
									<span>{contactNM.name}</span>
								</div>
							</div>
						)}
					</div>
				</div>
			)}
			<button onClick={() => createTodo()}>{t('Save')}</button>
		</div>
	);
};

export default TodosViewBoardFormTodo;
