/* eslint-disable unicorn/no-null */
import React from 'react';
import { TodosContract, TodosStates } from '../../../store/todos/type';
import { useLocation } from 'react-router-dom';
import TodosRowComponent from './todos-row.component';
import styles from '../todos.module.scss';
import TodosFormModal from '../modals/todos.form.modal';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { RouteUrls } from '../../../routes/routes-config';
import { IconContact, IconDown, IconMeetings, IconMeetingsPage, IconTag, IconText, IconUp } from '../../../shared/utils/icon';
import { t } from 'i18next';
import { reverseTodos } from '../../../store/todos/slice';
import { reverseTodosProject } from '../../../store/project/slice';

interface Props {
	todosAssignee: TodosContract[];
	height?: string,
	attendees?: { name: string, email: string, }[];
}

const TodosViewSimple: React.FC<Props> = ({ todosAssignee, height, attendees }) => {
	const location = useLocation();
	const { user } = useAppSelector((state) => state.user);
	const dispatch = useAppDispatch();

	const [openUpdate, setOpenUpdate] = React.useState(false);
	const [todoToUpdate, setTodoToUpdate] = React.useState<TodosContract | undefined>(undefined);

	const handleUpdate = (NoteTD: TodosContract) => {
		setTodoToUpdate(NoteTD);
		setOpenUpdate(!openUpdate);
	};

	const toggleUpdate = () => {
		setOpenUpdate(!openUpdate);
	};

	const [arrow, setArrow] = React.useState(false);

	const handleArrow = () => {
		setArrow(!arrow);
		dispatch(reverseTodos());
		dispatch(reverseTodosProject());
	};

	React.useEffect(() => {
		if (location.search.includes('?create')) {
			const dullTodo = {
				id: '',
				title: 'new todo',
				text: '',
				state: TodosStates.TODO,
				dueDate: '',
				duration: 0,
				graphUserId: user ? user.graphUserId : '',
				noteId: '',
				assigneeDisplayName: user ? user.email : '',
				assigneeName: user ? user.name : '',
				graphiCalUId: null,
				meetingGraphId: null,
				meetingName: null,
				meetingStartDate: null,
				createdOn: new Date(),
				tags: [],
				archived: false,
				projectId: '',
			};

			const todo = dullTodo as unknown as TodosContract;

			if (todo) {
				handleUpdate(todo);
			}
		} else if (location.search.includes('?view')) {
			const id = location.search.split('/')[1];

			const todo = todosAssignee && todosAssignee.find((todoA) => todoA.id === id);

			if (todo) {
				handleUpdate(todo);
			}
		}
	}, [location]);

	const currentPath = window.location.pathname;
	const todosPagePath = RouteUrls.Todos;
	const renderTodos = currentPath === todosPagePath || currentPath === todosPagePath + '/';

	return (
		<div>
			<div>
				{/* <thead>
					<tr style={{ border: 'none' }}>
						<th></th>
						<th></th>
						{!renderTodos && <th></th>}
						<th></th>
						{renderTodos && <th></th>}
						{renderTodos && <th></th>}
						<th></th>
					</tr>
				</thead> */}
				<div className={styles.headTitle} >
					<div className={styles.flexHeader}>
						<div className={styles.title} style={{ color: '#2E4552' }}>
							<IconText /> {t('Todos')}
						</div>
						<div>
							{arrow === false && (
								<span
									style={{ cursor: 'pointer' }}
									onClick={() => {
										handleArrow();
									}}>
									<IconUp />
								</span>
							)}
							{arrow === true && (
								<span
									style={{ cursor: 'pointer' }}
									onClick={() => {
										handleArrow();
									}}>
									<IconDown />
								</span>
							)}
						</div>
					</div>

					<div className={styles.titleHeader}>
						<div className={styles.title}>
							<IconMeetings />
							{t('Due date')}
						</div>
					</div>
					{!renderTodos && (
						<div className={styles.titleHeader}>
							<div className={styles.title}>
								<IconContact />
								{t('Assignees')}
							</div>
						</div>
					)}
					<div className={styles.titleHeader}>
						<div className={styles.title}>
							<IconTag /> {t('Tags')}
						</div>
					</div>
					{renderTodos && (
						<div className={styles.titleHeader}>
							<div className={styles.title}>
								<IconMeetingsPage /> {t('Connected meeting')}
							</div>
						</div>
					)}
					{renderTodos && (
						<div className={styles.titleHeader}>
							<div className={styles.title}>
								<IconMeetingsPage /> {t('Connected project')}
							</div>
						</div>
					)}

					<div className={styles.endColumn}></div>
				</div>
				<div style={{ height: height ?? 'min-content', overflowY: 'auto', paddingTop: '10px' }}>
					<TodosRowComponent
						isSimpleList={true}
						todos={todosAssignee}
						meetingIndex={0}
						handleUpdate={handleUpdate}
					/>

					{openUpdate && todoToUpdate && (
						<TodosFormModal toggle={toggleUpdate} attendees={attendees} handleToggle={toggleUpdate} todo={todoToUpdate} />
					)}
				</div>
			</div>
		</div>
	);
};

export default TodosViewSimple;
