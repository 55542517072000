import React from 'react';
import styles from './settings-user-management.component.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useTranslation } from 'react-i18next';
import { UserStatus } from '../../../../store/user/type';
import { CompanyContract, RequestContract, UserDBContract } from '../../../../store/settings/type';
import {
	acceptOrRevoqueARequestForACompany,
	getAllRequestForACompany,
	getCompanyForUser,
	revokeUser,
	updateCompanyAllowOpt,
	updateUserAllowOption,
} from '../../../../store/settings/slice';
import classNames from 'classnames/bind';
import { toast } from '../../../../shared/components/modals/toast/toast-manager';
import { DateTimeFormat } from '../../../../shared/utils/date.util';
import {
	IconAddUser,
	IconCheck,
	IconClose,
	IconEdit,
	IconLink,
	IconMore,
	IconSend,
	IconSimplePlus,
} from '../../../../shared/utils/icon';
import { getInvitationLink, resendInvitationUser } from '../../../../store/invitations/slice';
import SettingsUserInvite from './settings-user-invite';
import SettingsUserUpdate from './settings-user-update';
import DeleteConfirmBox from '../../../../shared/utils/delete-box';
import { ThemeProvider, Toggle } from '@fluentui/react';
import { calendar } from '../../../../shared/utils/themes';
import SettingUserOffer from './setting-user-offer';
import { BillingPlan } from '../../../../store/billing/type';

const cx = classNames.bind(styles);

function SettingsUserManagementComponent() {
	const modalRef = React.useRef<HTMLDivElement>(null);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { user } = useAppSelector((state) => state.user);
	const { company, companyRequests } = useAppSelector((state) => state.settings);
	const { billingInfo } = useAppSelector((state) => state.billing);

	const [openOptions, setOpenOptions] = React.useState<number | undefined>(undefined);
	const [userToUpdate, setUserToUpdate] = React.useState<UserDBContract | undefined>(undefined);
	const [openUpdate, setOpenUpdate] = React.useState(false);
	const [openInvite, setOpenInvite] = React.useState(false);
	const [openConfirmBox, setOpenConfirmBox] = React.useState(false);

	const handleOpenConfirmBox = () => {
		setOpenConfirmBox(!openConfirmBox);
	};

	const handleOptions = (index: number) => {
		setOpenOptions((prev) => (prev === index ? undefined : index));
	};

	const handleUserUpdateClick = (userTU: UserDBContract, index: number) => {
		setUserToUpdate(userTU);
		handleOptions(index);
	};

	const handleUpdate = (index: number) => {
		handleOptions(index);
		setOpenUpdate(!openUpdate);
	};

	const handleInvite = () => {
		setOpenInvite(!openInvite);
	};

	const handleUpdateModal = () => {
		setOpenUpdate(!openUpdate);
	};

	const handleRevokeUser = () => {
		user &&
			userToUpdate &&
			dispatch(revokeUser(userToUpdate.id)).then(() => {
				setOpenConfirmBox(!openConfirmBox);
				dispatch(getCompanyForUser());

				toast.show({
					id: user?.graphUserId,
					title: t('User delete success') as string,
					duration: 10000,
					type: 'success',
				});
			});
	};

	const handleResendInvitation = (index: number) => {
		if (userToUpdate) {
			const body = {
				mail: userToUpdate.username,
				role: userToUpdate.role,
			};
			handleOptions(index);
			dispatch(resendInvitationUser(body)).then(() => {
				dispatch(getCompanyForUser());

				toast.show({
					id: user?.graphUserId,
					title: t('Invitation send') as string,
					duration: 10000,
					type: 'success',
				});
			});
		}
	};

	const handleCopyLinkInvite = (index: number, userDB: UserDBContract) => {
		if (userDB) {
			const userId = userDB.id;

			dispatch(getInvitationLink(userId)).then((res) => {
				handleOptions(index);
				navigator.clipboard.writeText(res.payload as string);
				toast.show({
					id: user?.graphUserId,
					title: t('Link copied') as string,
					duration: 10000,
					type: 'success',
				});
			});
		}
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					setOpenOptions(undefined);
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [modalRef]);

	const onChangeOptionAllow = () => {
		if (user) {
			dispatch(updateCompanyAllowOpt()).then((res) => {
				const comp = res.payload as CompanyContract;
				dispatch(updateUserAllowOption(comp.allowNewUser));
			});
		}
	};

	const acceptRequest = (request: RequestContract, index: number) => {
		const span = document.querySelector(`#request${index}`);

		if (span) {
			span.classList.add('animationRequest');
		}

		const body = {
			id: request.id,
			mail: request.mail,
			companyId: request.companyId,
			companyNam: request.companyNam,
			requestAccepte: true,
		};

		dispatch(acceptOrRevoqueARequestForACompany(body)).then(() => {
			dispatch(getAllRequestForACompany());
		});
	};

	const refuseRequest = (request: RequestContract) => {
		const body = {
			id: request.id,
			mail: request.mail,
			companyId: request.companyId,
			companyNam: request.companyNam,
			requestAccepte: false,
		};

		dispatch(acceptOrRevoqueARequestForACompany(body)).then(() => {
			dispatch(getAllRequestForACompany());
		});
	};

	return (
		<div className={styles.container}>
			<SettingUserOffer />
			<div className={styles.optionsButtons}>
				{billingInfo && billingInfo.plan === BillingPlan.PERUSER ? (
					<>
						{billingInfo && company && billingInfo.usersForPlan - company.user.length > 0 ? (
							<button className={styles.invite} onClick={() => handleInvite()}>
								<div className={styles.icon}>
									<IconSimplePlus />
								</div>
								<span onClick={() => handleInvite()}>{t('Invite user')}</span>
							</button>
						) : (
							<button className={styles.invite}>
								<div className={styles.icon}>
									<IconAddUser />
								</div>
								<span>{t('Add new seats')}</span>
							</button>
						)}
					</>
				) : (
					<button className={styles.invite} onClick={() => handleInvite()}>
						<div className={styles.icon}>
							<IconSimplePlus />
						</div>
						<span onClick={() => handleInvite()}>{t('Invite user')}</span>
					</button>
				)}

				<ThemeProvider applyTo='body' theme={calendar}>
					<div className={styles.toggle}>
						<Toggle onChange={onChangeOptionAllow} checked={company?.allowNewUser} />
						<label>{t('Allow new users with same domain')}</label>
					</div>
				</ThemeProvider>
			</div>
			<h2>{t('Users')}</h2>
			<div className={styles.overview}>
				<table>
					<thead>
						<tr>
							<th>{t('user name')}</th>
							<th>{t('Email address')}</th>
							<th>{t('Last connection')}</th>
							<th>{t('Status')}</th>
							<th>{t('Role with the Organisation')}</th>
							<th>{t('Role')}</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{company?.user.map((userForCompany: UserDBContract, index: number) => {
							return (
								<tr key={index}>
									<td className={styles.displayName}>
										{userForCompany.name}
									</td>
									<td>
										{userForCompany.username}
									</td>
									<td>{DateTimeFormat(userForCompany.lastConnection)}</td>
									<td
										className={cx(styles.statut, {
											active: userForCompany.status === UserStatus.ACTIVE,
											pending: userForCompany.status === UserStatus.PENDING,
										})}>
										{userForCompany.status}
									</td>
									<td>{userForCompany.jobTitle}</td>
									<td>{userForCompany.role}</td>
									<td className={styles.moreCol}>
										<span
											className={styles.more}
											onClick={() => handleUserUpdateClick(userForCompany, index)}>
											<IconMore />
										</span>
										{openOptions === index && userToUpdate && (
											<div className={styles.options} ref={modalRef}>
												<div
													className={styles.edit}
													onClick={() => {
														handleUpdate(index);
													}}>
													<IconEdit /> <span>{t('Edit user')}</span>
												</div>
												{userToUpdate.status !== UserStatus.ACTIVE && (
													<>
														<div
															className={styles.edit}
															onClick={() => {
																handleResendInvitation(index);
															}}>
															<IconSend />
															<span>{t('Resend invitation')}</span>
														</div>
														<div
															className={styles.edit}
															onClick={() => {
																handleCopyLinkInvite(index, userForCompany);
															}}>
															<IconLink />
															<span>{t('Copy link invitation')}</span>
														</div>
													</>
												)}
												{user?.email !== userToUpdate.username && (
													<div
														className={styles.remove}
														onClick={() => {
															setOpenConfirmBox(!openConfirmBox);
															handleOptions(index);
														}}>
														<IconClose /> <span>{t('Remove access')}</span>
													</div>
												)}
											</div>
										)}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>

			<div className={styles.request}>
				<h2>{t('Requests Access')}</h2>
				<div className={styles.overview}>
					<table>
						<thead>
							<tr>
								<th>{t('Email address')}</th>
								<th>{t('Status')}</th>
								<th>{t('Accept')}</th>
								<th>{t('Refuse')}</th>
							</tr>
						</thead>
						<tbody>
							{companyRequests &&
								companyRequests.map((request, index) => (
									<tr key={request.id}>
										<td>{request.mail}</td>
										<td>
											{request.requestAccepte ? (
												<span>{t('Validated')}</span>
											) : (
												<span id={`request${index}`}>{t('Pending')}</span>
											)}
										</td>
										<td>
											{!request.requestAccepte ? (
												<>
													{billingInfo && billingInfo.plan === BillingPlan.PERUSER ? (
														<>
															{billingInfo &&
															company &&
															billingInfo.usersForPlan - company.user.length > 0 ? (
																<div
																	className={styles.accept}
																	onClick={() => acceptRequest(request, index)}>
																	<IconCheck />
																</div>
															) : (
																<a
																	href='https://appsource.microsoft.com/fr-fr/product/web-apps/productivity5srl1685460155895.fivedaysperuser?tab=Overview'
																	target='_blank'
																	rel='noreferrer'>
																	<button className={styles.invite}>
																		<div className={styles.icon}>
																			<IconAddUser />
																		</div>
																		<span>{t('Add new seats')}</span>
																	</button>
																</a>
															)}
														</>
													) : (
														<div
															className={styles.accept}
															onClick={() => acceptRequest(request, index)}>
															<IconCheck />
														</div>
													)}
												</>
											) : (
												<div className={styles.hasAccept}>
													<IconCheck />
												</div>
											)}
										</td>
										<td>
											<div className={styles.refuse} onClick={() => refuseRequest(request)}>
												<IconClose />
											</div>
										</td>
									</tr>
								))}
						</tbody>
					</table>
				</div>
			</div>

			{openUpdate && userToUpdate && <SettingsUserUpdate close={handleUpdateModal} userToUpdate={userToUpdate} />}

			{openInvite && <SettingsUserInvite close={handleInvite} />}

			{openConfirmBox && userToUpdate && (
				<DeleteConfirmBox
					handleDelete={handleRevokeUser}
					handleConfirmBox={handleOpenConfirmBox}
					message='Are you sure you want to revoke this user?'
				/>
			)}
		</div>
	);
}

export default SettingsUserManagementComponent;
