import React, { useEffect, useState } from 'react';
import MeetingsOverviewComponent from './components/meetingsList/meetings-overview.component';
import MeetingDetailsComponent from './components/meetingDetails/meeting-details.component';
import styles from './meetings.module.scss';
import DateSelectorComponent from './components/calendar/date-selector.component';
import DateDetailsComponent from './components/calendar/date-details.component';
import { MeetingContract } from '../../store/meetings/types';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
	getMeetings,
	getMeetingsForSeries,
	setSelectedMeeting,
	getMeetingsForCalendar,
} from '../../store/meetings/slice';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/nl';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { RouteSearchParams, RouteUrls } from '../../routes/routes-config';
import { togglePanel } from '../../store/layout/slice';
import { PanelState } from '../../store/layout/types';
import {
	formatedDateTZ,
	getDateFormatHeader,
	getDateLessOneMonths,
	getDayLessOneDayStart,
	getDayPastOneDay,
	getDayPastOneDayStart,
	getDayPastOneMonth,
	getHour,
	getRightTimeSlot,
	getStartOfDayDate,
	getStartOfMonth,
} from '../../shared/utils/date.util';
import { usePrevious } from '../../shared/utils/hooks';
import { IconDownArrow, IconLeftChev, IconRightChev, IconSimplePlus } from '../../shared/utils/icon';
import { isSameDay } from 'date-fns';
import { useTranslation } from 'react-i18next';
import MeetingCreateModal from './modals/meeting-create.modal';
import RoundedButton from '../../shared/components/buttons/roundedBtn/rounded-btn.component';

initializeIcons();

const MeetingsPage = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { user } = useAppSelector((state) => state.user);
	const [selectedDate, setSelectedDate] = useState<Date>(new Date(formatedDateTZ(getStartOfDayDate(new Date()))));
	const [selectedDateCalendar, setSelectedDateCalendar] = useState<Date>(
		new Date(formatedDateTZ(getStartOfDayDate(new Date()))),
	);
	const previousDate = usePrevious(selectedDate);
	const { selectedMeeting, meetings, meetingsRequestStatus } = useAppSelector((state) => state.meetings);
	const [searchParams, setSearchParams] = useSearchParams();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [dateType, setDateType] = useState<string | undefined>(undefined);

	const [showCalendar, setShowCalendar] = React.useState(false);

	const handleCalander = () => {
		setShowCalendar(!showCalendar);
	};

	const startDate = getDayLessOneDayStart(selectedDate);
	const endDate = getDayPastOneDayStart(selectedDate);
	const dateRange = { startDate, endDate };

	const handleDateSelect = async (newDate: Date, type?: string) => {
		setDateType(type);
		setSelectedDateCalendar(newDate);

		if (type !== 'month' && user) {
			setDateType(undefined);
			navigate(RouteUrls.Meetings);
			const formatedDate = formatedDateTZ(newDate);
			searchParams.set(RouteSearchParams.Date, formatedDate);
			searchParams.delete(RouteSearchParams.MeetingId);
			setSearchParams(searchParams, {});
			dispatch(setSelectedMeeting(undefined));
			setShowCalendar(false);
		}
	};

	useEffect(() => {
		dispatch(togglePanel(PanelState.Visible));
	}, [dispatch]);

	useEffect(() => {
		if (dateType !== 'month' && user && (!previousDate || selectedDate.getTime() !== previousDate.getTime())) {
			dispatch(getMeetings(dateRange));
		}
	}, [selectedDate]);

	useEffect(() => {
		const meetingId = searchParams.get(RouteSearchParams.MeetingId);
		if (meetingId && user && dateType !== 'month') {
			const matchingMeeting = meetings?.find((meeting: MeetingContract) => meeting.iCalUId === meetingId);
			if (matchingMeeting) {
				dispatch(setSelectedMeeting(matchingMeeting));
			}
		}
	}, [searchParams, selectedDate, meetings]);

	useEffect(() => {
		const date = searchParams.get(RouteSearchParams.Date);
		if (date && user && dateType !== 'month') {
			setSelectedDate(new Date(date));
		}
	}, [searchParams]);

	const [openCalendar, setOpenCalendar] = React.useState(false);

	const handleOpenCalendar = () => {
		setOpenCalendar(!openCalendar);
	};

	React.useEffect(() => {
		const now = new Date();
		const meetingId = searchParams.get(RouteSearchParams.MeetingId);
		const meetingsRightDay = meetings?.filter((meeting) => isSameDay(new Date(meeting.start), selectedDate));
		const meetingsWAD = meetingsRightDay?.filter((meeting) => meeting.isAllDay === false);

		if (user && !meetingId && dateType !== 'month') {
			if (meetingsRightDay && meetingsRightDay.length > 0) {
				if (!isSameDay(selectedDate, new Date())) {
					if (meetingsWAD && meetingsRightDay.length > 1) {
						dispatch(setSelectedMeeting(meetingsWAD[0]));
					} else {
						dispatch(setSelectedMeeting(meetingsRightDay[0]));
					}
				} else {
					if (meetingsWAD && meetingsRightDay.length > 1) {
						const currentMeeting = meetingsWAD.find((meeting) => now < new Date(meeting.end));
						if (currentMeeting) {
							dispatch(setSelectedMeeting(currentMeeting));
						} else {
							const lastMeeting = meetingsWAD.reduce((prevMeeting, meeting) =>
								new Date(meeting.end) > new Date(prevMeeting.end) ? meeting : prevMeeting,
							);

							dispatch(setSelectedMeeting(lastMeeting));
						}
					} else {
						const currentMeeting = meetingsRightDay.find((meeting) => now < new Date(meeting.end));
						if (currentMeeting) {
							dispatch(setSelectedMeeting(currentMeeting));
						} else {
							const lastMeeting = meetingsRightDay.reduce((prevMeeting, meeting) =>
								new Date(meeting.end) > new Date(prevMeeting.end) ? meeting : prevMeeting,
							);

							dispatch(setSelectedMeeting(lastMeeting));
						}
					}
				}
			} else {
				dispatch(setSelectedMeeting(undefined));
			}
		}
	}, [selectedDate, meetings, searchParams]);

	const [toggleCreateMeeting, setToggleCreateMeeting] = React.useState(false);
	const handleToggleCreateMeeting = () => {
		setToggleCreateMeeting(!toggleCreateMeeting);
	};

	const modalRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					setSelectedDateCalendar(selectedDate);
					setDateType('');
					handleCalander();
				}, 0);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleCalander]);

	React.useEffect(() => {
		if (user && selectedMeeting && selectedMeeting.seriesMasterId) {
			const startDateS = getDateLessOneMonths(new Date(selectedMeeting.start));
			const endDateS = getDayPastOneMonth(new Date(selectedMeeting.start));
			const dateRangeS = { startDate: startDateS, endDate: endDateS, seriesId: selectedMeeting.seriesMasterId };
			dispatch(getMeetingsForSeries(dateRangeS));
		}
	}, [selectedMeeting]);

	React.useEffect(() => {
		const startOfMonth = getStartOfMonth(selectedDateCalendar);
		dispatch(getMeetingsForCalendar({ startDate: startOfMonth, endDate: getDayPastOneMonth(startOfMonth) }));
	}, [selectedDateCalendar]);

	return (
		<div className={styles.main}>
			<div className={styles.calendar}>
				<div className={styles.titlePlace}>
					<h1 className={styles.title}>{t('Meetings')}</h1>
					<RoundedButton onClick={() => handleToggleCreateMeeting()} style={{ marginTop: '10px' }} />
				</div>
				<div className={styles.dateSelect}>
					<span className={showCalendar ? styles.activeDate : styles.date} onClick={() => handleCalander()}>
						{getDateFormatHeader(selectedDate)} <IconDownArrow />{' '}
					</span>
					<div className={styles.arrows}>
						<div
							className={styles.chevArrow}
							onClick={() => handleDateSelect(getDayLessOneDayStart(selectedDate))}>
							<IconLeftChev />
						</div>
						<div
							className={styles.chevArrow}
							onClick={() => handleDateSelect(getDayPastOneDayStart(selectedDate))}>
							<IconRightChev />
						</div>
					</div>
				</div>
				<div className={styles.pickerDate}>
					{showCalendar && (
						<div ref={modalRef}>
							<DateSelectorComponent
								selectedDate={selectedDateCalendar}
								onDateSelect={handleDateSelect}
							/>
						</div>
					)}
				</div>
				{selectedMeeting && <DateDetailsComponent date={selectedDate} />}

				{toggleCreateMeeting && (
					<MeetingCreateModal
						close={handleToggleCreateMeeting}
						date={getRightTimeSlot(selectedDate, getHour(new Date()), 0).toString()}
						onDateSelect={() => undefined}
					/>
				)}
			</div>

			{openCalendar && (
				<div className={styles.modalCalendar}>
					<div className={styles.titlePlace}>
						<h1 className={styles.title}>{t('Meetings')}</h1>
						<div className={styles.button} onClick={() => handleToggleCreateMeeting()}>
							<IconSimplePlus />
						</div>
					</div>
					<div className={styles.dateSelect}>
						<span className={styles.date} onClick={() => handleCalander()}>
							{getDateFormatHeader(selectedDate)} <IconDownArrow />{' '}
						</span>
						<div className={styles.arrows}>
							<div onClick={() => handleDateSelect(getDayLessOneDayStart(selectedDate))}>
								<IconLeftChev />
							</div>
							<div onClick={() => handleDateSelect(getDayPastOneDayStart(selectedDate))}>
								<IconRightChev />
							</div>
						</div>
					</div>
					<div className={styles.pickerDate}>
						{showCalendar && (
							<div ref={modalRef}>
								<DateSelectorComponent
									selectedDate={selectedDateCalendar}
									onDateSelect={handleDateSelect}
								/>
							</div>
						)}
					</div>
					<DateDetailsComponent date={selectedDate} toggle={handleOpenCalendar} />

					{toggleCreateMeeting && (
						<MeetingCreateModal
							close={handleToggleCreateMeeting}
							date={getRightTimeSlot(selectedDate, getHour(new Date()), 0).toString()}
							onDateSelect={() => undefined}
						/>
					)}
				</div>
			)}

			{!selectedMeeting && !openCalendar && (
				<MeetingsOverviewComponent
					date={selectedDate}
					meetings={meetings}
					meetingsRequestStatus={meetingsRequestStatus}
					toggle={handleOpenCalendar}
				/>
			)}
			{selectedDate && selectedMeeting && !openCalendar && (
				<MeetingDetailsComponent
					toggle={handleOpenCalendar}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
				/>
			)}
		</div>
	);
};

const MeetingsPageWrapper: React.FC = () => {
	const { id, date } = useParams<{ id: string; date: string }>();
	const { user } = useAppSelector((state) => state.user);
	const dispatch = useAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		if (user) {
			const today = formatedDateTZ(new Date());
			// if (!searchParams.has(RouteSearchParams.Date)) {
			// 	searchParams.set(RouteSearchParams.Date, today);
			// 	searchParams.set(RouteSearchParams.MeetingId, selectedMeeting ? selectedMeeting.iCalUId : '');
			// 	setSearchParams(searchParams);
			// }

			if (!id && !date) {
				const startDate = new Date();
				const endDate = getDayPastOneDay(startDate);
				const dateRange = { startDate, endDate };
				dispatch(getMeetings(dateRange));
			}

			if (id && date) {
				const startDate = new Date(date);
				const endDate = getDayPastOneDay(startDate);
				const dateRange = { startDate, endDate };
				dispatch(getMeetings(dateRange));
			}
		}
	}, [id, date, user]);

	return <MeetingsPage />;
};

export default MeetingsPageWrapper;
