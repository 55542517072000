/* eslint-disable unicorn/no-lonely-if */
import React, { useState } from 'react';
import styles from './meeting-details.component.module.scss';
import MeetingDetailsHeader from './meetingDetailsHeader/meetings-details-header';
import MeetingDetailsBody from './meetingDetailsBody/meetings-details-body';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { MeetingContract, TagContract } from '../../../../store/meetings/types';
import {
	updateSelectedMeetingTags,
	updateMeetingTags,
	setPrevMeeting,
	getMeetingInformations,
	getMeeting,
	setPrevMeetings,
} from '../../../../store/meetings/slice';
import { getFilesForAMeetingShared, getMeetingsCollaborators } from '../../../../store/sharing/slice';
import { IconLeftChev } from '../../../../shared/utils/icon';
import { useParams } from 'react-router-dom';
import { MeetingForSeriesContract } from '../../../../store/series/type';
import { meetingsForSeries } from '../../../../store/series/slice';
import { getOneProject } from '../../../../store/project/slice';
import MeetingDetailsShimmer from './meetingDetailsShimmers/meeting-details-shimmer.component';
import { setPrevMeetingsNotes } from '../../../../store/notes/slice';

interface props {
	toggle?: () => void;
	isLoading: boolean;
	setIsLoading: (param: boolean) => void;
}

const MeetingDetailsComponent = ({ toggle, isLoading, setIsLoading }: props) => {
	const dispatch = useAppDispatch();
	const { selectedMeeting, meetings } = useAppSelector((state) => state.meetings);
	const { meetingsForSeriesState } = useAppSelector((state) => state.meetings);
	const { shareMeetingDataPrev } = useAppSelector((state) => state.sharing);
	const { user } = useAppSelector((state) => state.user);

	const params = useParams();
	const shareId = params.shareID;

	const storedAccessMail = localStorage.getItem(`accessMail/${shareId}`);

	const handleTagsChanged = (tags: TagContract[]) => {
		selectedMeeting &&
			user &&
			dispatch(
				updateMeetingTags({
					tags,
					selectedMeetingID: selectedMeeting?.iCalUId,
				}),
			).then((response) => {
				dispatch(updateSelectedMeetingTags(response.payload as TagContract[]));
			});
	};

	const [series, setSeries] = React.useState<MeetingForSeriesContract[]>();

	React.useEffect(() => {
		const fetchData = async () => {
			if (selectedMeeting) {
				if (user) {
					await dispatch(getMeetingsCollaborators(selectedMeeting.iCalUId));

					await dispatch(meetingsForSeries(selectedMeeting.iCalUId)).then((res) => {
						const data = res.payload as MeetingForSeriesContract[];
						const sortedData =
							data !== undefined
								? [...data].sort(
										(a, b) =>
											new Date(a.meetingStartDate).getTime() -
											new Date(b.meetingStartDate).getTime(),
								  )
								: [];
						setSeries(sortedData);
					});

					await dispatch(getMeetingInformations(selectedMeeting.iCalUId));

					if (selectedMeeting.projectId) await dispatch(getOneProject(selectedMeeting.projectId));

					setIsLoading(false);
				} else {
					await dispatch(
						getFilesForAMeetingShared({
							iCalUId: selectedMeeting.iCalUId,
							userId: storedAccessMail ? storedAccessMail : '',
						}),
					);

					await dispatch(setPrevMeetingsNotes(undefined));

					await dispatch(getMeetingsCollaborators(selectedMeeting.iCalUId));

					await dispatch(getMeetingInformations(selectedMeeting.iCalUId));

					setIsLoading(false);
				}
			} else {
				setIsLoading(false);
			}
		};
		fetchData();
	}, [selectedMeeting]);

	const handleCalendar = () => {
		if (toggle) toggle();
	};

	React.useEffect(() => {
		if (selectedMeeting && selectedMeeting?.type === 'singleInstance' && series && user) {
			const prevMeeting = series
				.filter(
					(meeting) =>
						new Date(meeting.meetingStartDate) < new Date(selectedMeeting?.start) &&
						meeting.iCalUId !== selectedMeeting.iCalUId,
				)
				.slice(-1)[0];

			const prevMeetings = series.filter(
				(meeting) =>
					new Date(meeting.meetingStartDate) < new Date(selectedMeeting?.start) &&
					meeting.iCalUId !== selectedMeeting.iCalUId,
			);

			if (prevMeeting) {
				dispatch(getMeeting(prevMeeting.iCalUId)).then((res) => {
					dispatch(setPrevMeeting(res.payload as MeetingContract));
				});
			} else {
				dispatch(setPrevMeeting(undefined));
			}

			if (prevMeetings) {
				dispatch(setPrevMeetings(prevMeetings));
			} else {
				dispatch(setPrevMeetings(undefined));
			}
		} else if (selectedMeeting && selectedMeeting.type !== 'singleInstance' && meetingsForSeriesState) {
			const prevMeeting = meetingsForSeriesState
				.filter((meeting) => new Date(meeting.start) < new Date(selectedMeeting?.start))
				.slice(-1)[0];

			const prevMeetings = meetingsForSeriesState.filter(
				(meeting) => new Date(meeting.start) < new Date(selectedMeeting?.start),
			);

			if (prevMeeting) {
				dispatch(setPrevMeeting(prevMeeting));
			} else {
				dispatch(setPrevMeeting(undefined));
			}

			if (prevMeetings) {
				dispatch(setPrevMeetings(prevMeetings));
			} else {
				dispatch(setPrevMeetings(undefined));
			}
		}
	}, [series]);

	React.useEffect(() => {
		if (!user && shareMeetingDataPrev) {
			const prevMeeting = shareMeetingDataPrev
				.filter((meeting) => meeting.iCalUId !== selectedMeeting?.iCalUId)
				.slice(-1)[0];

			const prevMeetings = shareMeetingDataPrev.filter((meeting) => meeting.iCalUId !== selectedMeeting?.iCalUId);

			if (prevMeeting) {
				dispatch(setPrevMeeting(prevMeeting));
			} else {
				dispatch(setPrevMeeting(undefined));
			}

			if (prevMeetings) {
				dispatch(setPrevMeetings(prevMeetings));
			} else {
				dispatch(setPrevMeetings(undefined));
			}
		}
	}, [shareMeetingDataPrev]);

	return (
		<div className={styles.main}>
			<div className={styles.meetingDetails}>
				<div className={styles.flex}>
					<div className={styles.icon} onClick={() => handleCalendar()}>
						<IconLeftChev />
					</div>
					<p>{selectedMeeting?.subject}</p>
				</div>

				{isLoading ? (
					<MeetingDetailsShimmer />
				) : (
					<>
						<MeetingDetailsHeader handleTagsChanged={handleTagsChanged} selectedMeeting={selectedMeeting} />
						<MeetingDetailsBody handleTagsChanged={handleTagsChanged} />
					</>
				)}
			</div>
		</div>
	);
};

export default MeetingDetailsComponent;
