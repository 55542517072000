import React, { FunctionComponent, Suspense } from 'react';
import ErrorBoundary from './shared/components/error-boundary/error-boundary';
import Header from './shared/components/header/header.component';
import styles from './main.module.scss';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { PanelState } from './store/layout/types';
import RoutesConfig, { RouteUrls } from './routes/routes-config';
import { useNavigate, useLocation } from 'react-router-dom';
import Sidebar from './shared/components/sidebar/sidebar.component';
import { usePrevious } from './shared/utils/hooks';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { getCurrentUser } from './store/user/slice';
import { transformLanguage } from './shared/utils/language';
import { BillingPlan } from './store/billing/type';
import logo from '../images/logo//LogoBlack.png';
import DraggableDesktopArea from './shared/components/draggableDesktopArea/draggable-desktop-area.component';

export const Main: FunctionComponent = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const { panelState } = useAppSelector((state) => state.layout);
	const { user } = useAppSelector((state) => state.user);
	const { billingInfo } = useAppSelector((state) => state.billing);
	const prevUser = usePrevious(user);
	const { i18n } = useTranslation();
	const browserLang = window.navigator.language;

	React.useEffect(() => {
		document.title = `5DAYS App - ${user?.email || 'Guest'}`;
	}, [user]);

	React.useEffect(() => {
		if (user?.userId !== null) {
			i18n.changeLanguage(user?.preferences.language);
			moment.locale(user?.preferences.language);
		} else {
			i18n.changeLanguage(transformLanguage(browserLang));
			moment.locale(transformLanguage(browserLang));
		}
	}, [user]);

	React.useEffect(() => {
		if (user === undefined) {
			dispatch(getCurrentUser());
		} else if (user && prevUser === undefined) {
			if (user.userId !== null) {
				if (user.invitationId) {
					navigate(`${RouteUrls.Invite}/${user.invitationId}`);
				} else {
					if (location.pathname !== RouteUrls.Invite || RouteUrls.Login || RouteUrls.Home) {
						if (location.pathname === RouteUrls.Home) {
							navigate(RouteUrls.Meetings);
						} else {
							navigate(location);
						}
					} else {
						navigate(RouteUrls.Meetings);
					}
				}
			} else {
				navigate(RouteUrls.Register);
			}
		}
	}, [user]);

	const endDateTrial = new Date(billingInfo ? billingInfo.endDateTrial : '');
	const currentDate = new Date();

	return (
		<>
			<ErrorBoundary>
				{user ? (
					<div className={styles.page}>
						{billingInfo && billingInfo.plan === BillingPlan.TRIAL && currentDate > endDateTrial && (
							<div className={styles.endTrial}>
								<div className={styles.modalTrial}>
									<h1 className={styles.trialTitle}>{t('Your trial period has expired')}</h1>
									<p>{t('To continue to use 5DAYS you have to buy a plan.')}</p>
									<div className={styles.flex}>
										<a
											href='https://appsource.microsoft.com/fr-fr/product/web-apps/productivity5srl1685460155895.fivedaysperuser?tab=Overview'
											target='_blank'
											rel='noreferrer'>
											<button>{t('See pricing & plans')}</button>
										</a>
										<img src={logo} alt='logo' />
									</div>
								</div>
							</div>
						)}
						{panelState !== PanelState.Hidden ? <Sidebar></Sidebar> : <></>}
						<Suspense fallback='Loading ...'>
							<div className={styles.mainContainer}>
								{window.api && window.api.isElectron && <DraggableDesktopArea />}
								{panelState !== PanelState.Hidden ? <Header /> : <></>}
								<div className={styles.main}>
									<RoutesConfig />
								</div>
							</div>
						</Suspense>
					</div>
				) : (
					<></>
				)}
			</ErrorBoundary>
		</>
	);
};
