import React, { useEffect } from 'react';
import styles from './register.module.scss';
import LoginIllustrationComponent from '../login/components/login-illustration.component';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { togglePanel } from '../../store/layout/slice';
import { PanelState } from '../../store/layout/types';
import RegisterFormComponent from './components/register-form.component';
import { extractDomain } from '../../shared/utils/domainext';
import { checkExistingCompany, checkRequestStatus } from '../../store/register/slice';
import { CompanyContract, RequestContract } from '../../store/settings/type';
import RegisterFormWithCompanyComponent from './components/register-form-with-company.component';
import { getInvitationData } from '../../store/invitations/slice';
import { RequestStatusType } from '../../store/shared/types';
import Loader from '../../shared/components/loader/loader.component';

function RegisterPage() {
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.user);
	const [existingCompany, setExistingCompany] = React.useState(false);
	const [company, setCompany] = React.useState<CompanyContract>();
	const [request, setRequest] = React.useState<RequestContract>();
	const [userDomain, setUserDomain] = React.useState<string>('');
	const { invitationData, invitationDataRequestStatus } = useAppSelector((state) => state.invitations);

	const storedInvitationId = localStorage.getItem('invite');

	React.useEffect(() => {
		if (user && storedInvitationId) {
			dispatch(getInvitationData(storedInvitationId));
		}
	}, [user]);

	React.useEffect(() => {
		if (user) {
			const domain = extractDomain(user.email);
			setUserDomain(domain);

			dispatch(checkExistingCompany(domain)).then((resCom) => {
				const result = resCom.payload;

				if (result === false) {
					setExistingCompany(false);
					setCompany(undefined);
				} else {
					setExistingCompany(true);
					setCompany(result);
					dispatch(checkRequestStatus(user.email)).then((resultRequest) => {
						const body = resultRequest.payload;

						if (body !== false) {
							setRequest(body as RequestContract);
						}
					});
				}
			});
		}
	}, [user, setUserDomain]);

	useEffect(() => {
		dispatch(togglePanel(PanelState.Hidden));
	}, [dispatch]);

	return (
		<div className={styles.container}>
			{!storedInvitationId && (
				<>
					{existingCompany === false && <RegisterFormComponent />}

					{company?.allowNewUser === true && <RegisterFormWithCompanyComponent company={company} />}

					{company?.allowNewUser === false && request && request.requestAccepte === true && (
						<RegisterFormWithCompanyComponent company={company} />
					)}

					<LoginIllustrationComponent />
				</>
			)}

			{storedInvitationId && (
				<>
					{invitationDataRequestStatus.type === RequestStatusType.InProgress && (
						<div className={styles.containerLoader}>
							<div className={styles.loader}>
								<Loader />
							</div>
						</div>
					)}
					{invitationDataRequestStatus.type === RequestStatusType.Success && (
						<>
							{existingCompany === true && company && invitationData && !invitationData.company && (
								<RegisterFormWithCompanyComponent company={company} />
							)}

							{invitationData && invitationData.company && (
								<RegisterFormWithCompanyComponent company={invitationData.company} />
							)}
						</>
					)}

					<LoginIllustrationComponent />
				</>
			)}
		</div>
	);
}

export default RegisterPage;
