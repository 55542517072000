/* eslint-disable unicorn/no-null */
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus, RequestStatusType, UpdateMeetingFilesAccess, createRequestStatus } from '../shared/types';
import ErrorUtil from '../../shared/utils/error.util';
import {
	MeetingsProjectContract,
	NotificationProjectContract,
	ProjectContract,
	UpdateProjectCount,
	UpdateSeeNotification,
	UpdateStateArchivePayload,
	UpdateStatePayload,
	UpdateStateTodoPayload,
} from './type';
import ProjectsAPI from '../../api/project-api';
import { NotesContract, People, updateTags } from '../notes/type';
import { FileContract, TagContract } from '../meetings/types';
import { TodosContract, UpdateTodo } from '../todos/type';
import { CommentsContract } from '../comments/type';

interface NotificationsState {
	projects?: ProjectContract[];
	projectsRequestStatus: RequestStatus;

	project?: ProjectContract;
	projectRequestStatus: RequestStatus;

	selectProject?: ProjectContract;

	projectsSharePeople?: People[];
	projectsSharePeopleRequestStatus: RequestStatus;

	projectsNotes?: NotesContract[];
	projectsNotesRequestStatus: RequestStatus;

	projectsTodos?: TodosContract[];
	projectsTodosRequestStatus: RequestStatus;

	projectsMeetings?: MeetingsProjectContract[];
	projectsMeetingsRequestStatus: RequestStatus;

	projectsNotesComments?: CommentsContract[];
	projectsNotesCommentsRequestStatus: RequestStatus;

	projectNotifications?: NotificationProjectContract[];
	projectNotificationsRequestStatus: RequestStatus;

	projectFiles?: FileContract[];
	projectFilesRequestStatus: RequestStatus;

	filesPeople?: People[];
	filesPeopleRequestStatus: RequestStatus;
}

const initialState: NotificationsState = {
	projects: undefined,
	projectsRequestStatus: createRequestStatus(RequestStatusType.New),

	project: undefined,
	projectRequestStatus: createRequestStatus(RequestStatusType.New),

	selectProject: undefined,

	projectsSharePeople: undefined,
	projectsSharePeopleRequestStatus: createRequestStatus(RequestStatusType.New),

	projectsNotes: undefined,
	projectsNotesRequestStatus: createRequestStatus(RequestStatusType.New),

	projectsTodos: undefined,
	projectsTodosRequestStatus: createRequestStatus(RequestStatusType.New),

	projectsMeetings: undefined,
	projectsMeetingsRequestStatus: createRequestStatus(RequestStatusType.New),

	projectsNotesComments: undefined,
	projectsNotesCommentsRequestStatus: createRequestStatus(RequestStatusType.New),

	projectNotifications: undefined,
	projectNotificationsRequestStatus: createRequestStatus(RequestStatusType.New),

	projectFiles: undefined,
	projectFilesRequestStatus: createRequestStatus(RequestStatusType.New),

	filesPeople: undefined,
	filesPeopleRequestStatus: createRequestStatus(RequestStatusType.New),
};

export const getProjects = createAsyncThunk('/projects', async (): Promise<ProjectContract[]> => {
	return ProjectsAPI.getCurrentUserProjects();
});

export const getOneProject = createAsyncThunk(
	'/projects/get/one',
	async (projectId: string): Promise<ProjectContract> => {
		return ProjectsAPI.getOneProject(projectId);
	},
);

export const createProjects = createAsyncThunk(
	'/projects/create',
	async (body: ProjectContract): Promise<ProjectContract> => {
		return ProjectsAPI.createProject(body);
	},
);

export const updateProjects = createAsyncThunk(
	'/projects/update',
	async (body: ProjectContract): Promise<ProjectContract> => {
		return ProjectsAPI.updateProject(body);
	},
);

export const deleteProject = createAsyncThunk('/projects/delete', async (projectId: string): Promise<undefined> => {
	return ProjectsAPI.deleteProject(projectId);
});

export const addTagsProject = createAsyncThunk('/projects/tags/add', async (body: updateTags): Promise<TagContract> => {
	return ProjectsAPI.addTagsProject(body);
});

export const deleteTagsFromProject = createAsyncThunk(
	'/projects/tags/delete',
	async ({ projectId, tagsId }: { projectId: string; tagsId: string }): Promise<undefined> => {
		return ProjectsAPI.deleteTagsFromProject(projectId, tagsId);
	},
);

export const postPeopleShareProject = createAsyncThunk(
	'/projects/share/post',
	async ({ body, projectId }: { body: People; projectId: string }): Promise<People> => {
		return ProjectsAPI.addPeopleShareProject(body, projectId);
	},
);

export const deletePeopleShareProject = createAsyncThunk(
	'/projects/share/delete',
	async ({ projectId, mail }: { projectId: string; mail: string }): Promise<undefined> => {
		return ProjectsAPI.deleteAPeopleShareProject(projectId, mail);
	},
);

export const getPeopleShareProject = createAsyncThunk(
	'/projects/share/get',
	async (projectId: string): Promise<People[]> => {
		return ProjectsAPI.getPeopleShareProject(projectId);
	},
);

export const getNotesProject = createAsyncThunk(
	'/projects/notes/get',
	async (projectId: string): Promise<NotesContract[]> => {
		return ProjectsAPI.getNotesProjects(projectId);
	},
);

export const getTodosProject = createAsyncThunk(
	'/projects/todos/get',
	async (projectId: string): Promise<TodosContract[]> => {
		return ProjectsAPI.getTodosProjects(projectId);
	},
);

export const getMeetingsProject = createAsyncThunk(
	'/projects/meetings/get',
	async (projectId: string): Promise<MeetingsProjectContract[]> => {
		return ProjectsAPI.getMeetingsProjects(projectId);
	},
);

export const getNotesCommentsProject = createAsyncThunk(
	'/projects/comments/get',
	async (projectId: string): Promise<CommentsContract[]> => {
		return ProjectsAPI.getNotesCommentsProjects(projectId);
	},
);

export const linkMeetingProject = createAsyncThunk(
	'/projects/meetings/create',
	async ({
		body,
		projectId,
	}: {
		body: MeetingsProjectContract;
		projectId: string;
	}): Promise<MeetingsProjectContract> => {
		return ProjectsAPI.postMeetingProjects(body, projectId);
	},
);

export const getNotificationsProject = createAsyncThunk(
	'/projects/notifications/get',
	async (projectId: string): Promise<NotificationProjectContract[]> => {
		return ProjectsAPI.getNotificationsProjects(projectId);
	},
);

export const SeeNotificationsProject = createAsyncThunk(
	'/projects/notifications/seen',
	async (notification: NotificationProjectContract): Promise<NotificationProjectContract> => {
		return ProjectsAPI.SeeNotificationsProjects(notification);
	},
);

export const SendNotificationsProject = createAsyncThunk(
	'/projects/notifications/post',
	async (body: NotificationProjectContract): Promise<NotificationProjectContract> => {
		return ProjectsAPI.postNotificationProjects(body);
	},
);

export const addProjectFile = createAsyncThunk(
	'project/files/addFile',
	async ({ body, projectId }: { body: FormData; projectId: string }): Promise<FormData> => {
		return ProjectsAPI.addFileToProject(body, projectId);
	},
);

export const deleteProjectFile = createAsyncThunk(
	'project/files/deleteFile',
	async ({ projectId, fileId }: { projectId: string; fileId: string }): Promise<undefined> => {
		return ProjectsAPI.deleteFileToProject(projectId, fileId);
	},
);

export const updateProjectFile = createAsyncThunk(
	'project/files/updateAccess',
	async ({
		body,
		projectId,
		fileId,
	}: {
		body: UpdateMeetingFilesAccess;
		projectId: string;
		fileId: string;
	}): Promise<FileContract> => {
		return ProjectsAPI.updateFileToProjectAccess(body, projectId, fileId);
	},
);

export const downloadProjectFile = createAsyncThunk(
	'project/files/downloadFile',
	async ({ projectId, fileId }: { projectId: string; fileId: string }): Promise<Blob> => {
		return ProjectsAPI.downloadFileProject(projectId, fileId);
	},
);

export const getFilesProject = createAsyncThunk(
	'/projects/files/get',
	async (projectId: string): Promise<FileContract[]> => {
		return ProjectsAPI.getFileProject(projectId);
	},
);

export const postPeopleShareFile = createAsyncThunk(
	'/projects/share/post/file',
	async ({ body, fileId }: { body: People; fileId: string }): Promise<People> => {
		return ProjectsAPI.addPeopleShareFile(body, fileId);
	},
);

export const deletePeopleShareFile = createAsyncThunk(
	'/projects/share/delete/file',
	async ({ fileId, mail }: { fileId: string; mail: string }): Promise<undefined> => {
		return ProjectsAPI.deleteAPeopleShareFile(fileId, mail);
	},
);

export const getPeopleShareFile = createAsyncThunk(
	'/projects/file/share/get',
	async (fileId: string): Promise<People[]> => {
		return ProjectsAPI.getPeopleShareFile(fileId);
	},
);

const slice = createSlice({
	name: 'projects',
	initialState,
	reducers: {
		setSelectedProject: (state, action: PayloadAction<ProjectContract | undefined>) => {
			state.selectProject = action.payload;
		},
		updateNoteProject: (state, action: PayloadAction<UpdateStatePayload>) => {
			const { id, newText } = action.payload;
			if (state.projectsNotes) {
				const note = state.projectsNotes.find((noteNT) => noteNT.id === id);
				if (note) {
					note.text = newText;
				}
			}
		},
		updateNoteTitleProject: (state, action: PayloadAction<UpdateStatePayload>) => {
			const { id, newText } = action.payload;
			if (state.projectsNotes) {
				const note = state.projectsNotes.find((noteNT) => noteNT.id === id);
				if (note) {
					note.title = newText;
				}
			}
		},
		updateCommentNote: (state, action: PayloadAction<UpdateStatePayload>) => {
			const { id, newText } = action.payload;
			if (state.projectsNotesComments) {
				const comment = state.projectsNotesComments.find((commentNT) => commentNT.id === id);
				if (comment) {
					comment.textComment = newText;
				}
			}
		},

		seeNotification: (state, action: PayloadAction<UpdateSeeNotification>) => {
			const notificationUpdate = action.payload;
			if (state.projectNotifications) {
				const notif = state.projectNotifications.find(
					(notification) => notification.id === notificationUpdate.notificationId,
				);
				if (notif) {
					notif.userSeenNotif.push(notificationUpdate.user);
				}
			}
		},

		updateTodoProject: (state, action: PayloadAction<UpdateTodo>) => {
			const { todoId, newTodo } = action.payload;
			if (state.projectsTodos) {
				const todoIndex = state.projectsTodos.findIndex((todo) => todo.id === todoId);
				if (todoIndex !== -1) {
					const updatedTodos = [...state.projectsTodos];
					updatedTodos[todoIndex] = {
						...updatedTodos[todoIndex],
						...newTodo,
					};

					state.projectsTodos = updatedTodos;
				}
			}
		},

		addproject: (state, action: PayloadAction<ProjectContract>) => {
			const newProject = action.payload;
			if (state.projects) {
				state.projects.push(newProject);
			}
		},

		deleteProjectState: (state, action: PayloadAction<string>) => {
			const projectId = action.payload;
			if (state.projects) {
				state.projects = state.projects.filter((project) => project.id !== projectId);
			}
		},

		updateSelectProject: (state, action: PayloadAction<ProjectContract>) => {
			const newProject = action.payload;
			if (state.selectProject) {
				state.selectProject = newProject;
			}
		},

		updateProjectState: (state, action: PayloadAction<ProjectContract>) => {
			const newProject = action.payload;
			if (state.projects) {
				const projectIndex = state.projects.findIndex((project) => project.id === newProject.id);
				if (projectIndex !== -1) {
					const updatedProjects = [...state.projects];
					updatedProjects[projectIndex] = {
						...updatedProjects[projectIndex],
						...newProject,
					};

					state.projects = updatedProjects;
				}
			}
		},

		updateTodoStateProject: (state, action: PayloadAction<UpdateStateTodoPayload>) => {
			const { todoId, stateTodo, indexProject, projectCol } = action.payload;
			if (state.projectsTodos) {
				const todo = state.projectsTodos.find((todoNT) => todoNT.id === todoId);
				if (todo) {
					todo.state = stateTodo;
				}

				if (todo && indexProject !== undefined) {
					state.projectsTodos.forEach((todoNT) => {
						const currentOrder = Number.parseInt(todoNT.orderProject || '0', 10);
						if (currentOrder >= indexProject) {
							todoNT.orderProject = (currentOrder + 1).toString();
						}
					});

					todo.orderProject = indexProject.toString();
				}

				if (todo) {
					todo.columnProjectId = projectCol ? projectCol : null;
				}
			}
		},

		deleteNotesProject: (state, action: PayloadAction<string>) => {
			const id = action.payload;
			if (state.projectsNotes) {
				state.projectsNotes = state.projectsNotes.filter((note) => note.id !== id);
			}
		},

		addNotesProject: (state, action: PayloadAction<NotesContract>) => {
			const newNote = action.payload;
			if (state.projectsNotes) {
				state.projectsNotes = [newNote, ...state.projectsNotes];
			}
		},

		deleteTodoProject: (state, action: PayloadAction<string>) => {
			const id = action.payload;
			if (state.projectsTodos) {
				state.projectsTodos = state.projectsTodos.filter((note) => note.id !== id);
			}
		},

		addTodoProject: (state, action: PayloadAction<TodosContract>) => {
			const newTodo = action.payload;
			if (state.projectsTodos) {
				state.projectsTodos = [newTodo, ...state.projectsTodos];
			}
		},

		deleteFilesProject: (state, action: PayloadAction<string>) => {
			const id = action.payload;
			if (state.projectFiles) {
				state.projectFiles = state.projectFiles.filter((file) => file.id !== id);
			}
		},

		addFilesProject: (state, action: PayloadAction<FileContract>) => {
			const newFiles = action.payload;
			if (state.projectFiles) {
				state.projectFiles = [newFiles, ...state.projectFiles];
			}
		},

		updateNoteProjectArchive: (state, action: PayloadAction<UpdateStateArchivePayload>) => {
			const { id, archive } = action.payload;
			if (state.projectsNotes) {
				const note = state.projectsNotes.find((noteNT) => noteNT.id === id);
				if (note) {
					note.archived = archive;
				}
			}
		},

		updateProjectFiles: (state, action: PayloadAction<FileContract>) => {
			const newFile = action.payload;
			if (state.projectFiles) {
				const projectFilesIndex = state.projectFiles.findIndex((file) => file.id === newFile.id);
				if (projectFilesIndex !== -1) {
					const updatedProjectFile = [...state.projectFiles];
					updatedProjectFile[projectFilesIndex] = {
						...updatedProjectFile[projectFilesIndex],
						...newFile,
					};

					state.projectFiles = updatedProjectFile;
				}
			}
		},

		addPeopleShareFile: (state, action: PayloadAction<any>) => {
			const newPeople = action.payload;
			if (state.filesPeople) {
				state.filesPeople.push(newPeople);
			}
		},

		deletePeopleFile: (state, action: PayloadAction<string>) => {
			const mail = action.payload;
			if (state.filesPeople) {
				state.filesPeople = state.filesPeople.filter((note) => note.mail !== mail);
			}
		},

		sortTodosProject: (state) => {
			if (state.projectsTodos) {
				state.projectsTodos.sort((b, a) => {
					const aDate = a.dueDate || a.meetingStartDate || a.createdOn;
					const bDate = b.dueDate || b.meetingStartDate || b.createdOn;

					if (aDate && bDate) {
						return new Date(aDate).getTime() - new Date(bDate).getTime();
					} else if (aDate) {
						return -1;
					} else if (bDate) {
						return 1;
					} else {
						return 0;
					}
				});
			}
		},

		reverseTodosProject: (state) => {
			if (state.projectsTodos) state.projectsTodos = [...state.projectsTodos].reverse();
		},

		addNoteComments: (state, action: PayloadAction<CommentsContract>) => {
			const newComment = action.payload;
			if (state.projectsNotesComments) {
				state.projectsNotesComments.push(newComment);
			}
		},

		updateProjectCount: (state, action: PayloadAction<UpdateProjectCount>) => {
			const val = action.payload;

			if (val && state.selectProject) {
				state.selectProject.countFiles = val.filesCount;
				state.selectProject.countMeetings = val.meetingsCount;
				state.selectProject.countNotes = val.noteCount;
				state.selectProject.countTodos = val.todoCount;
			}
		},

		updateProjectCountTodos: (state, action: PayloadAction<string>) => {
			const val = action.payload;

			if (val && state.selectProject && state.selectProject.id === val) {
				state.selectProject.countTodos = state.selectProject.countTodos
					? +state.selectProject.countTodos - 1
					: 1;
			}
		},

		updateProjectCountTodosAdd: (state, action: PayloadAction<string>) => {
			const val = action.payload;

			if (val && state.selectProject && state.selectProject.id === val) {
				state.selectProject.countTodos = state.selectProject.countTodos
					? +state.selectProject.countTodos + 1
					: 1;
			}
		},
	},
	extraReducers(builder): void {
		builder.addCase(getProjects.pending, (state) => {
			state.projectsRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getProjects.fulfilled, (state, action) => {
			state.projectsRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.projects = action.payload;
		});
		builder.addCase(getProjects.rejected, (state, action) => {
			state.projectsRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getPeopleShareProject.pending, (state) => {
			state.projectsSharePeopleRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getPeopleShareProject.fulfilled, (state, action) => {
			state.projectsSharePeopleRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.projectsSharePeople = action.payload;
		});
		builder.addCase(getPeopleShareProject.rejected, (state, action) => {
			state.projectsSharePeopleRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getNotesProject.pending, (state) => {
			state.projectsNotesRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getNotesProject.fulfilled, (state, action) => {
			state.projectsNotesRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.projectsNotes = action.payload;
		});
		builder.addCase(getNotesProject.rejected, (state, action) => {
			state.projectsNotesRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getTodosProject.pending, (state) => {
			state.projectsTodosRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getTodosProject.fulfilled, (state, action) => {
			state.projectsTodosRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.projectsTodos = action.payload;
		});
		builder.addCase(getTodosProject.rejected, (state, action) => {
			state.projectsTodosRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getMeetingsProject.pending, (state) => {
			state.projectsMeetingsRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getMeetingsProject.fulfilled, (state, action) => {
			state.projectsMeetingsRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.projectsMeetings = action.payload;
		});
		builder.addCase(getMeetingsProject.rejected, (state, action) => {
			state.projectsMeetingsRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getNotesCommentsProject.pending, (state) => {
			state.projectsNotesCommentsRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getNotesCommentsProject.fulfilled, (state, action) => {
			state.projectsNotesCommentsRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.projectsNotesComments = action.payload;
		});
		builder.addCase(getNotesCommentsProject.rejected, (state, action) => {
			state.projectsNotesCommentsRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getNotificationsProject.pending, (state) => {
			state.projectNotificationsRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getNotificationsProject.fulfilled, (state, action) => {
			state.projectNotificationsRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.projectNotifications = action.payload;
		});
		builder.addCase(getNotificationsProject.rejected, (state, action) => {
			state.projectNotificationsRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getFilesProject.pending, (state) => {
			state.projectFilesRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getFilesProject.fulfilled, (state, action) => {
			state.projectFilesRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.projectFiles = action.payload;
		});
		builder.addCase(getFilesProject.rejected, (state, action) => {
			state.projectFilesRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getPeopleShareFile.pending, (state) => {
			state.filesPeopleRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getPeopleShareFile.fulfilled, (state, action) => {
			state.filesPeopleRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.filesPeople = action.payload;
		});
		builder.addCase(getPeopleShareFile.rejected, (state, action) => {
			state.filesPeopleRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});
	},
});

const { actions, reducer } = slice;
export const {
	setSelectedProject,
	updateNoteProject,
	updateNoteTitleProject,
	updateCommentNote,
	seeNotification,
	updateTodoProject,
	addproject,
	updateProjectState,
	deleteProjectState,
	updateTodoStateProject,
	addNotesProject,
	addTodoProject,
	deleteNotesProject,
	deleteTodoProject,
	updateNoteProjectArchive,
	deleteFilesProject,
	addFilesProject,
	updateProjectFiles,
	addPeopleShareFile,
	deletePeopleFile,
	sortTodosProject,
	reverseTodosProject,
	updateSelectProject,
	addNoteComments,
	updateProjectCount,
	updateProjectCountTodos,
	updateProjectCountTodosAdd,
} = actions;
export default reducer;
