import React, { FormEvent, useEffect, useRef, useState, ReactNode } from 'react';
import styles from './input.module.scss';
import useClickOutside from '../../hooks/use-click-outside.hook';
import { IconClose, IconSimplePlus } from '../../utils/icon';
import { Attendees } from '../../../pages/meetings/modals/meeting-create.modal';
import { ShimmerDiv } from 'shimmer-effects-react';

export interface Items {
	id?: string;
	leading?: ReactNode;
	title: string;
	subTitle?: string;
	trailing?: string;
}

interface Props {
	attendees?: Attendees[];
	placeHolder: string;
	register?: any;
	defaultValue?: Items;
	getValue?: (item: Items | undefined) => void;
	onChange?: (e: FormEvent<HTMLInputElement>) => void;
	type?: string;
	renderModal?: Items[];
	resetFieldOnSelect?: boolean;
	handleRemoveAttendee: (email: string) => void;
	isLoading: boolean;
}

const AttendeesInput: React.FC<Props> = ({
	placeHolder,
	register,
	defaultValue,
	onChange,
	getValue,
	renderModal,
	resetFieldOnSelect = false,
	attendees,
	handleRemoveAttendee,
	isLoading,
}) => {
	const [constantValue, setConstantValue] = useState<string>('');
	const [inputValue, setInputValue] = useState<string>('');
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const modalRef = useRef<HTMLDivElement | null>(null);
	const [isPlaceHolderShrunk, setIsPlaceHolderShrunk] = useState<boolean>(false);

	useEffect(() => {
		if (defaultValue) {
			setInputValue(defaultValue.title);
			setConstantValue(defaultValue.title);
			if (getValue) getValue(defaultValue);
		}
	}, []);

	const handleClick = (item: Items) => {
		setIsModalOpen(false);

		if (resetFieldOnSelect) {
			setInputValue('');
		}

		if (getValue) getValue(item);
	};

	const shimmerCount = renderModal ? renderModal.length : 0;

	useEffect(() => {
		if (attendees?.length) setIsPlaceHolderShrunk(true);
		else setIsPlaceHolderShrunk(false);
	}, [attendees]);

	const handleInput = (e: FormEvent<HTMLInputElement>) => {
		setInputValue(e.currentTarget.value);
	};

	useClickOutside(modalRef, () => {
		setIsModalOpen(false);

		if (inputValue.length !== 0) {
			setInputValue(constantValue);
			return;
		}

		setInputValue('');

		if (getValue) getValue(undefined);
	});

	return (
		<div className={styles.inputContainer}>
			{attendees &&
				attendees.map((attendee) => (
					<div key={attendee.emailAddress.address} className={styles.inputAttendeeWrapper}>
						<div className={styles.inputAttendees}>
							{attendee.emailAddress.name} | {attendee.emailAddress.address}
						</div>
						<div
							className={styles.attendeeIcons}
							onClick={() => handleRemoveAttendee(attendee.emailAddress.address)}>
							<IconClose />
						</div>
					</div>
				))}

			<input
				spellCheck={false}
				className={styles.attendeeInput}
				style={{ paddingInline: isPlaceHolderShrunk ? '5px' : '' }}
				defaultValue={defaultValue}
				{...register}
				placeholder=''
				type='text'
				value={inputValue}
				onInput={handleInput}
				onChange={onChange}
				onClick={() => setIsModalOpen(true)}
			/>
			<div className={`${styles.meetingPlaceholder} ${isPlaceHolderShrunk ? styles.shrunk : ''}`}>
				{placeHolder}
			</div>
			{renderModal && (
				<div className={styles.inputIcon}>
					<IconSimplePlus />
				</div>
			)}
			{renderModal && isModalOpen && (
				<div className={styles.inputModal} ref={modalRef}>
					{!isLoading &&
						renderModal
							?.filter((element) => element.title.toLowerCase().includes(inputValue.toLowerCase()))
							.map((item) => (
								<div
									className={styles.inputModalItems}
									style={{ paddingBlock: '8px' }}
									key={item.id}
									onClick={() => handleClick(item)}>
									{item.leading && <div>{item.leading}</div>}
									<div>
										<div>{item.title}</div>
										<div className={styles.modalSubTitle}>{item.subTitle}</div>
									</div>
								</div>
							))}
					{isLoading &&
						Array.from({ length: shimmerCount }).map((_, index) => (
							<div className={styles.inputModalItems} style={{ paddingBlock: '8px' }} key={index}>
								<ShimmerDiv
									mode='custom'
									height={32}
									width={32}
									rounded={50}
									from='#edebe9'
									via='white'
									to='#edebe9'
								/>
								<div>
									<ShimmerDiv
										className={styles.titleShimmer}
										mode='custom'
										height={12}
										width={92}
										rounded={1}
										from='#edebe9'
										via='white'
										to='#edebe9'
									/>
									<ShimmerDiv
										className={styles.modalSubTitle}
										mode='custom'
										height={10}
										width={140}
										rounded={1}
										from='#edebe9'
										via='white'
										to='#edebe9'
									/>
								</div>
							</div>
						))}
				</div>
			)}
		</div>
	);
};

export default AttendeesInput;
