/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from 'react';
import styles from './date-details.component.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

import { getStartOfDay, getTimeFormat } from '../../../../shared/utils/date.util';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RouteSearchParams, RouteUrls } from '../../../../routes/routes-config';
import { TodosStates } from '../../../../store/todos/type';
import { IconMic, IconNotes, IconNotRecording, IconTodosMeeting } from '../../../../shared/utils/icon';
import isSameDay from 'date-fns/isSameDay';
import { setSelectedMeeting } from '../../../../store/meetings/slice';
import { MeetingContract } from '../../../../store/meetings/types';
import DateDetailsShimmer from './shimmers/date-details-shimmer.component';
import { RequestStatusType } from '../../../../store/shared/types';

interface MeetingsOverviewProps {
	date: Date;
	toggle?: () => void;
}

const DateDetailsComponent: React.FC<React.PropsWithChildren<MeetingsOverviewProps>> = ({ date, toggle }) => {
	const { t } = useTranslation();
	const { meetings, selectedMeeting, meetingsRequestStatus } = useAppSelector((state) => state.meetings);
	const { todosAssignee } = useAppSelector((state) => state.todos);
	const { agendaAll } = useAppSelector((state) => state.agenda);
	const { user } = useAppSelector((state) => state.user);
	const { shareMeetingData, shareMeetingDataNext, shareMeetingDataPrev } = useAppSelector((state) => state.sharing);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const handleCalendar = () => {
		if (toggle) toggle();
	};

	const selectMeetingRef = useRef<HTMLDivElement>(null);

	const [shareMeetings, setShareMeetings] = React.useState<MeetingContract[]>();

	React.useEffect(() => {
		if (shareMeetingData && shareMeetingDataNext && shareMeetingDataPrev) {
			const combinedArray = [...shareMeetingDataNext, ...shareMeetingDataPrev];

			const sortedArray = combinedArray.sort((a, b) => {
				const dateA = new Date(a.start).getTime();
				const dateB = new Date(b.start).getTime();

				return dateA - dateB;
			});

			setShareMeetings(sortedArray);
		}
	}, [shareMeetingData, shareMeetingDataNext, shareMeetingDataPrev]);

	React.useEffect(() => {
		const timeoutId = setTimeout(() => {
			if (selectMeetingRef.current) {
				selectMeetingRef.current.scrollIntoView({
					behavior: 'smooth',
					block: 'nearest',
				});
			}
		}, 100);

		return () => clearTimeout(timeoutId);
	}, []);

	return (
		<>
			<div className={styles.main}>
				<div className={styles.list}>
					{meetingsRequestStatus.type === RequestStatusType.InProgress && (
						<DateDetailsShimmer />
					)}
					{meetingsRequestStatus.type === RequestStatusType.Success && meetings
						?.filter((meeting) => meeting.isAllDay === true && isSameDay(new Date(meeting.start), date))
						?.map((meeting) => (
							<div
								key={meeting.graphEventId}
								className={
									selectedMeeting?.iCalUId === meeting.iCalUId ? styles.selectMeeting : styles.meeting
								}
								onClick={() => {
									const params: any = {};
									params[RouteSearchParams.Date] = getStartOfDay(meeting.start);
									params[RouteSearchParams.MeetingId] = meeting.iCalUId;
									navigate(`${RouteUrls.Meetings}?${new URLSearchParams(params).toString()}`);
									handleCalendar();
								}}>
								<div>
									{meeting.isAllDay === false ? (
										<span>{getTimeFormat(meeting.start, meeting.end)}</span>
									) : (
										<span>{t('All day')}</span>
									)}
								</div>
								<p className={styles.title}>{meeting.subject}</p>
								<div className={styles.task}>
									{meeting?.countAgenda && meeting.countAgenda > 0 && (
										<>
											<IconMic />
										</>
									)}

									{meeting?.countTodos && meeting.countTodos > 0 && (
										<>
											<IconTodosMeeting />
										</>
									)}
								</div>
							</div>
						))}
					{meetingsRequestStatus.type === RequestStatusType.Success && meetings
						?.filter((meeting) => meeting.isAllDay === false && isSameDay(new Date(meeting.start), date))
						?.map((meeting) => (
							<div
								key={meeting.graphEventId}
								ref={
									meeting.graphEventId === selectedMeeting?.graphEventId
										? selectMeetingRef
										: undefined
								}
								className={
									selectedMeeting?.iCalUId === meeting.iCalUId ? styles.selectMeeting : styles.meeting
								}
								onClick={() => {
									const params: any = {};
									params[RouteSearchParams.Date] = getStartOfDay(meeting.start);
									params[RouteSearchParams.MeetingId] = meeting.iCalUId;
									navigate(`${RouteUrls.Meetings}?${new URLSearchParams(params).toString()}`);
									handleCalendar();
								}}>
								<div className={styles.meetingHour}>
									{meeting.isAllDay === false ? (
										<span>{getTimeFormat(meeting.start, meeting.end)}</span>
									) : (
										<span>{t('All day')}</span>
									)}
								</div>
								<p className={styles.title}>{meeting.subject}</p>
								<div className={styles.task}>
									{(meeting?.countAgenda ?? 0) > 0 && (
										<div className={styles.micIcon}>
											<IconMic />
										</div>
									)}

									{(meeting?.countTodos ?? 0) > 0 && (
										<div className={styles.todoIcon}>
											<IconTodosMeeting />
										</div>
									)}
									{(meeting?.countNotes ?? 0) > 0 && (
										<div className={styles.noteIcon}>
											<IconNotes height='17' width='17' />
										</div>
									)}
								</div>
							</div>
						))}
					{meetingsRequestStatus.type === RequestStatusType.Success && !user &&
						shareMeetings &&
						shareMeetings
							.filter((meeting) => isSameDay(date, new Date(meeting.start)))
							.map((meeting) => (
								<div
									key={meeting.iCalUId}
									ref={
										meeting.graphEventId === selectedMeeting?.graphEventId
											? selectMeetingRef
											: undefined
									}
									className={
										selectedMeeting?.graphEventId === meeting.graphEventId
											? styles.selectMeeting
											: styles.meeting
									}
									onClick={() => {
										dispatch(setSelectedMeeting(meeting));
										handleCalendar();
									}}>
									<div>
										{meeting.isAllDay === false ? (
											<span>{getTimeFormat(meeting.start, meeting.end)}</span>
										) : (
											<span>{t('All day')}</span>
										)}
									</div>
									<p className={styles.title}>{meeting.subject}</p>
								</div>
							))}
					{meetingsRequestStatus.type === RequestStatusType.Success && !user && selectedMeeting && isSameDay(date, new Date(selectedMeeting.start)) && (
						<div
							key={selectedMeeting.iCalUId}
							ref={
								selectedMeeting.graphEventId === selectedMeeting?.graphEventId
									? selectMeetingRef
									: undefined
							}
							className={
								selectedMeeting?.graphEventId === selectedMeeting.graphEventId
									? styles.selectMeeting
									: styles.meeting
							}
							onClick={() => {
								dispatch(setSelectedMeeting(selectedMeeting));
								handleCalendar();
							}}>
							<div>
								{selectedMeeting.isAllDay === false ? (
									<span>{getTimeFormat(selectedMeeting.start, selectedMeeting.end)}</span>
								) : (
									<span>{t('All day')}</span>
								)}
							</div>
							<p className={styles.title}>{selectedMeeting.subject}</p>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default DateDetailsComponent;
