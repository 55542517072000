import React, { LegacyRef, useEffect } from 'react';
import { TodosContract, TodosStates } from '../../../store/todos/type';
import styles from './todo-board.module.scss';
import { TagContract } from '../../../store/meetings/types';
import { hexToRgb } from '../../../shared/utils/hextorgb';
import { Avatar } from '../../../shared/utils/avatar';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
	completeTodos,
	deleteTodo,
	deleteTodosAssignee,
	updateTodoAssignee,
	updateTodos,
	updateTodoState,
} from '../../../store/todos/slice';
import { IconArchive, IconDelete } from '../../../shared/utils/icon';
import DeleteConfirmBox from '../../../shared/utils/delete-box';
import {
	deleteTodoProject,
	SendNotificationsProject,
	updateProjectCountTodos,
	updateTodoProject,
	updateTodoStateProject,
} from '../../../store/project/slice';
import { NotificationsType } from '../../../store/notifications/type';
import { SendNotifications } from '../../../store/notifications/slice';
import { NotificationsProjectType } from '../../../store/project/type';
import { PersonaSize } from '@fluentui/react';
import TodosViewBoardFormTodo from './todo-view-board-form-todo';
import { updateSelectedMeetingCountTodos } from '../../../store/meetings/slice';

interface Props {
	todosAssignee: TodosContract[];
	handleUpdate: (NoteTD: TodosContract) => void;
	handleDragStart: (e: React.DragEvent<HTMLDivElement>, todoId: string, todoPreviousState: TodosStates) => void;
	handleDrop: (
		e: React.DragEvent<HTMLDivElement>,
		newState: TodosStates,
		index: number,
		onBottomDrag: boolean,
		col?: string,
	) => void;
	dragTodo: TodosContract | undefined;
	setDragTodo: Function;
	isDraggedOver: string | undefined;
	setIsDraggedOver: Function;
	col: TodosStates;
	dragElement: LegacyRef<HTMLDivElement>;
	handleCurrentDragElement: Function;
	heightElement: number;
	colCustomId?: string;
	addTodo?: boolean;
	setAddTodo?: (el: boolean) => void;
	page: string;
	projectId?: string;
}
const TodosViewBoardCol: React.FC<Props> = ({
	todosAssignee,
	handleUpdate,
	handleDragStart,
	handleDrop,
	dragTodo,
	setDragTodo,
	isDraggedOver,
	setIsDraggedOver,
	col,
	dragElement,
	handleCurrentDragElement,
	heightElement,
	colCustomId,
	addTodo,
	setAddTodo,
	page,
	projectId,
}) => {
	const { user } = useAppSelector((state) => state.user);
	const dispatch = useAppDispatch();
	const [isDragOverEmpty, setIsDragOverEmpty] = React.useState(false);
	const columnRef = React.useRef<HTMLDivElement>(null);
	const [openConfirmBox, setOpenConfirmBox] = React.useState(false);
	const [todoToUpdate, setTodoToUpdate] = React.useState<TodosContract | undefined>(undefined);
	const [isDragging, setIsDragging] = React.useState<boolean>(false);
	const [isActive, setIsActive] = React.useState<boolean>(false);

	const handleConfirmBox = () => {
		setOpenConfirmBox(!openConfirmBox);
	};

	const handleTodoClickOption = (NoteTD: TodosContract) => {
		setTodoToUpdate(NoteTD);
	};

	const handleDeleteTodo = () => {
		if (todoToUpdate && user?.graphUserId === todoToUpdate.graphUserId) {
			if (todoToUpdate.graphiCalUId) {
				dispatch(updateSelectedMeetingCountTodos(todoToUpdate.graphiCalUId));
			}

			if (todoToUpdate.projectId) {
				dispatch(updateProjectCountTodos(todoToUpdate.projectId));
			}

			dispatch(deleteTodo(todoToUpdate?.id)).then((res) => {
				if (res.meta.requestStatus === 'fulfilled') {
					dispatch(deleteTodosAssignee(todoToUpdate.id));
					dispatch(deleteTodoProject(todoToUpdate.id));
					setOpenConfirmBox(false);
				}
			});
		}
	};

	const handleClose = () => {
		if (setAddTodo !== null && setAddTodo !== undefined) {
			setIsActive(true);
			setTimeout(() => {
				setAddTodo(!addTodo);
				setIsActive(false);
			}, 200);
		}
	};

	const handleArchivedTodo = (todo: TodosContract) => {
		if (todo) {
			const body = {
				id: todo.id,
				title: todo.title,
				text: todo.text,
				state: todo.state,
				dueDate: todo.dueDate,
				duration: todo.duration,
				graphUserId: todo.graphUserId,
				noteId: todo.noteId,
				assigneeDisplayName: todo.assigneeDisplayName,
				graphiCalUId: todo.graphiCalUId,
				meetingGraphId: todo.meetingGraphId,
				meetingName: todo.meetingName,
				meetingStartDate: todo.meetingStartDate,
				createdOn: todo.createdOn,
				tags: undefined,
				assigneeName: todo.assigneeName,
				archived: todo.archived === false ? true : false,
				projectId: todo.projectId,
			};

			dispatch(updateTodos({ body, todoId: todo.id })).then((res) => {
				if (res.meta.requestStatus === 'fulfilled') {
					const newTodo = res.payload as TodosContract;

					dispatch(updateTodoAssignee({ todoId: newTodo.id, newTodo }));
					dispatch(updateTodoProject({ todoId: newTodo.id, newTodo }));
				}
			});
		}
	};

	const handleDragOver = (e: React.DragEvent<HTMLDivElement>, todo: TodosContract) => {
		setIsDraggedOver(todo.id);
		const column = columnRef.current;
		if (column) {
			const rect = column.getBoundingClientRect();
			const scrollTop = column.scrollTop;
			const offsetTop = e.clientY - rect.top;
			const scrollBottom = rect.bottom - e.clientY;

			if (offsetTop < 50) {
				column.scrollTop = scrollTop - 20;
			} else if (scrollBottom < 50) {
				column.scrollTop = scrollTop + 20;
			}
		}
	};

	const getIndexTodoAssignee = (index: number): number => {
		return index - 1 !== -1
			? Number(
					todosAssignee[index - 1].orderAssignee !== undefined
						? Number(todosAssignee[index - 1].orderAssignee) + 1
						: 0,
			  )
			: 0;
	};

	const getIndexTodoProject = (index: number): number => {
		return index - 1 !== -1
			? Number(
					todosAssignee[index - 1].orderProject !== undefined
						? Number(todosAssignee[index - 1].orderProject) + 1
						: 0,
			  )
			: 0;
	};

	const onChangeComplete = (checked: boolean, todo: TodosContract) => {
		const body = {
			state: checked === true ? TodosStates.DONE : TodosStates.TODO,
			index: page && page === 'todos' ? todo.orderAssignee : todo.orderProject,
		};

		const bodyUpdate = {
			todoId: todo.id,
			stateTodo: checked === true ? TodosStates.DONE : TodosStates.TODO,
		};

		dispatch(updateTodoState(bodyUpdate));
		dispatch(updateTodoStateProject(bodyUpdate));

		dispatch(completeTodos({ body, todoId: todo.id })).then((res) => {
			if (res.meta.requestStatus === 'fulfilled') {
				dispatch(updateTodoState(bodyUpdate));
				dispatch(updateTodoStateProject(bodyUpdate));

				if (checked === true && user && user.graphUserId !== todo.graphUserId) {
					const bodyNotification = {
						id: undefined,
						userFromName: user.displayName,
						userFromMail: user.email,
						userToName: todo.graphUserId,
						userToMail: todo.graphUserId,
						type: NotificationsType.TODO_COMPLETE,
						noteId: undefined,
						todoId: todo.id,
						graphiCalUId: undefined,
						projectId: undefined,
						meetingStartDate: undefined,
						itemTitle: todo.title,
						visible: true,
						meetingName: undefined,
					};

					dispatch(SendNotifications(bodyNotification));
				}

				if (checked === true && user && todo.projectId) {
					const bodyNotificationProject = {
						id: '',
						userFromName: user.name,
						userFromMail: user.email,
						type: NotificationsProjectType.TODO_COMPLETE,
						noteId: '',
						todoId: todo.id,
						todoDueDate: todo.dueDate,
						itemTitle: todo.title,
						graphiCalUId: '',
						meetingStartDate: '',
						meetingEndDate: '',
						meetingName: '',
						meetingAttendees: [],
						projectId: todo.projectId,
						userSeenNotif: [],
						todoAssignee: todo.assigneeDisplayName,
					};

					dispatch(SendNotificationsProject(bodyNotificationProject));
				}
			}
		});
	};

	useEffect(() => {
		handleCurrentDragElement();
	}, [dragTodo]);

	return (
		<div
			className={styles.colTodo}
			ref={columnRef}
			onDrop={(e) => {
				if (isDraggedOver === undefined) {
					handleDrop(
						e,
						col,
						page && page === 'todos'
							? getIndexTodoAssignee(todosAssignee.length)
							: getIndexTodoProject(todosAssignee.length),
						false,
						colCustomId,
					);
					setIsDraggedOver(undefined);
					setDragTodo(undefined);
				}
			}}>
			<div
				className={isActive ? styles.isActive : ''}
				style={{ maxHeight: addTodo ? '350px' : '0px', overflow: 'hidden', transition: '.2s' }}>
				{addTodo && col !== TodosStates.DONE && (
					<TodosViewBoardFormTodo
						todoState={col}
						close={handleClose}
						projectId={projectId ? projectId : undefined}
					/>
				)}
			</div>
			{todosAssignee.length !== 0 &&
				todosAssignee
					.sort(
						(a, b) =>
							Number(page && page === 'todos' ? a.orderAssignee : a.orderProject) -
							Number(page && page === 'todos' ? b.orderAssignee : b.orderProject),
					)
					.map((todo, index) => (
						<div
							className={styles.shadow}
							key={todo.id}
							onDragOver={(e) => {
								handleDragOver(e, todo);
							}}
							style={{
								backgroundColor: dragTodo?.id === todo.id && isDraggedOver === todo.id ? '' : '',
							}}>
							<div
								style={{
									transition: dragTodo !== undefined ? '0.1s' : '0s',
									height:
										isDraggedOver === todo.id &&
										isDraggedOver !== undefined &&
										dragTodo !== undefined &&
										dragTodo?.id !== todo.id
											? `${heightElement}px`
											: '',
								}}
								className={
									isDraggedOver === todo.id &&
									isDraggedOver !== undefined &&
									dragTodo !== undefined &&
									dragTodo?.id !== todo.id
										? styles.activePlaceHolder
										: styles.placeholder
								}
								onDrop={(e) => {
									handleDrop(e, col, getIndexTodoAssignee(index), true, colCustomId);
									setDragTodo(undefined);
									setIsDraggedOver(undefined);
								}}
								onDragOver={() => setIsDraggedOver(todo.id)}
								onDragLeave={() => {
									if (isDraggedOver !== todo.id) {
										setIsDraggedOver(undefined);
									}
								}}
								onDragEnd={() => {
									setDragTodo(undefined);
									setIsDraggedOver(undefined);
								}}
							/>

							<div
								id={`todoid${todo.id}`}
								ref={dragTodo?.id === todo.id ? dragElement : undefined}
								className={styles.item}
								onClick={() => handleUpdate(todo)}
								draggable={true}
								onDragStart={(e) => {
									handleDragStart(e, todo.id, todo.state);
									setDragTodo(todo);
								}}
								onDragOver={() => {
									setIsDraggedOver(todo.id);
								}}
								onDragLeave={() => {
									if (isDraggedOver !== todo.id) {
										setIsDraggedOver(undefined);
									}
								}}
								onDragEnd={() => {
									setIsDraggedOver(undefined);
									setDragTodo(undefined);
								}}>
								<div
									className={styles.leftLine}
									style={{
										backgroundColor: todo.tags.length !== 0 ? `${todo.tags[0].color}` : '#00ad56',
									}}
								/>
								<div className={styles.cardWrapper}>
									<div className={styles.info}>
										<div className={styles.check}>
											<input
												type='checkbox'
												onClick={(e) => e.stopPropagation()}
												onChange={(e) => {
													onChangeComplete(e.target.checked, todo);
													e.stopPropagation();
												}}
												checked={todo.state === TodosStates.DONE ? true : false}
											/>
											<div className={styles.checkbox}></div>
											<p>{todo.title}</p>
										</div>

										{user?.graphUserId === todo.graphUserId && (
											<div className={styles.icons}>
												<div
													onClick={(e) => {
														handleTodoClickOption(todo);
														setOpenConfirmBox(!openConfirmBox);
														e.stopPropagation();
													}}
													className={styles.icon}>
													<IconDelete />
												</div>
												<div
													onClick={(e) => {
														handleArchivedTodo(todo);
														e.stopPropagation();
													}}
													className={styles.icon}>
													<IconArchive />
												</div>
											</div>
										)}
									</div>

									<div className={styles.bottomCard}>
										{/* {todo.tags.length === 0 && <div></div>} */}

										<div className={styles.tagWrapper}>
											{todo.tags.length !== 0 &&
												todo.tags?.slice(0, 3).map((tag: TagContract, indexTodoTag) => (
													<div className={styles.dropFlex} key={indexTodoTag}>
														<div
															className={styles.tag}
															style={{
																backgroundColor: `rgba(${hexToRgb(tag.color)}, 0.1)`,
																border: `1px solid ${tag.color}`,
															}}>
															<div style={{ color: tag.color, fontWeight: '600' }}>
																{tag.title}
															</div>
														</div>
													</div>
												))}
											{todo.tags.length > 3 && (
												<div className={styles.moreTags}>+{todo.tags.length - 3}</div>
											)}
										</div>

										<div style={{ width: '28px' }}>
											<Avatar
												size={PersonaSize.size28}
												name={todo.assigneeName}
												mail={todo.assigneeDisplayName}
												index={index}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					))}

			<div className={styles.shadow}>
				<div
					className={styles.placeholder}
					style={{ width: '300px' }}
					onDragEnter={() => setIsDraggedOver(undefined)}
				/>
			</div>

			{openConfirmBox && todoToUpdate && (
				<DeleteConfirmBox
					handleDelete={handleDeleteTodo}
					handleConfirmBox={handleConfirmBox}
					message='Are you sure you want to delete this todo?'
				/>
			)}
		</div>
	);
};

export default TodosViewBoardCol;
