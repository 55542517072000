import { UserLanguage } from '../../store/shared/types';

export const getListTemplate = (language: UserLanguage) => {
	switch (language) {
		case UserLanguage.EN:
			return `
            Human: You will always provide three outputs :
            Human: Summarize the context of the meeting; title of the section: 'Context of the meeting'
            Human: Highlight key outcome of the meeting in bullet points, if any; title of the secion: 'Highlights keys'
            Human: Define next steps in bullet points, if any; title of the section: 'Next steps'
            Human: You will avoid any topic about:
            Human: Personal life of participants
            Human: Pointing out late participant or early leaver in the meeting
            Human: Introduce your answer, just print the text
        `;

		case UserLanguage.FR:
			return `
            Humain : Vous fournirez toujours trois sorties : 
            Humain : Résumez le contexte de la réunion ; titre de la section : « Contexte de la réunion » 
            Humain : Mettez en avant les points clés de la réunion sous forme de puces, si nécessaire ; titre de la section : « Points clés » 
            Humain : Définissez les prochaines étapes sous forme de puces, si nécessaire ; titre de la section : « Prochaines étapes » 
            Humain : Vous éviterez tout sujet concernant : Humain : La vie personnelle des participants 
            Humain : Mentionner un participant en retard ou quittant la réunion en avance
            Humain : Introduisez votre réponse, imprimez simplement le texte
        `;

		case UserLanguage.DE:
			return `
            Mensch: Du wirst immer drei Ausgaben liefern: 
            Mensch: Fasse den Kontext des Meetings zusammen; Titel der Sektion: „Kontext des Meetings“ 
            Mensch: Hebe wichtige Ergebnisse des Meetings in Aufzählungspunkten hervor, falls vorhanden; Titel der Sektion: „Wichtige Punkte“ 
            Mensch: Definiere die nächsten Schritte in Aufzählungspunkten, falls vorhanden; Titel der Sektion: „Nächste Schritte“ 
            Mensch: Du vermeidest jegliche Themen über: 
            Mensch: Das Privatleben der Teilnehmer Mensch: Die Erwähnung von zu spät kommenden oder frühzeitig gehenden Teilnehmern
            Mensch: Führen Sie Ihre Antwort ein, drucken Sie einfach den Text
        `;

		case UserLanguage.NL:
			return `
           Mens: Je zult altijd drie uitkomsten geven: 
           Mens: Vat de context van de vergadering samen; titel van de sectie: 'Context van de vergadering' 
           Mens: Markeer belangrijke resultaten van de vergadering in bullet points, indien van toepassing; titel van de sectie: 'Belangrijke punten' 
           Mens: Definieer de volgende stappen in bullet points, indien van toepassing; titel van de sectie: 'Volgende stappen' 
           Mens: Je vermijdt elk onderwerp over: Mens: Het persoonlijke leven van de deelnemers 
           Mens: Het vermelden van te late deelnemers of deelnemers die de vergadering vroeg verlaten
           Mens: Introduceer je antwoord, druk gewoon de tekst af
        `;

		default:
			return `
            Human: You will always provide three outputs :
            Human: Summarize the context of the meeting; title of the section: 'Context of the meeting'
            Human: Highlight key outcome of the meeting in bullet points, if any; title of the secion: 'Highlights keys'
            Human: Define next steps in bullet points, if any; title of the section: 'Next steps'
            Human: You will avoid any topic about:
            Human: Personal life of participants
            Human: Pointing out late participant or early leaver in the meeting
            Human: Introduce your answer, just print the text
        `;
	}
};

// -------------

export const getQuestionTemplate = (language: UserLanguage, question: string) => {
	switch (language) {
		case UserLanguage.EN:
			return `
          Human: You will always provide at least one output:
          Human: Summarize the context of the meeting
          Human: ${question}
          Human: You will avoid any topic about:
          Human: Personal life of participants
          Human: Pointing out late participants or early leavers in the meeting
        `;

		case UserLanguage.FR:
			return `
          Humain: Vous fournirez toujours au moins une réponse :
          Humain: Résumez le contexte de la réunion
          Humain: ${question}
          Humain: Vous éviterez tout sujet concernant :
          Humain: La vie personnelle des participants
          Humain: Le fait de signaler les participants en retard ou les départs anticipés
        `;

		case UserLanguage.DE:
			return `
          Mensch: Sie werden immer mindestens eine Antwort geben:
          Mensch: Fassen Sie den Kontext des Treffens zusammen
          Mensch: ${question}
          Mensch: Sie werden jedes Thema vermeiden über:
          Mensch: Das Privatleben der Teilnehmer
          Mensch: Das Ansprechen von verspäteten Teilnehmern oder frühzeitigen Verlassen des Treffens
        `;

		case UserLanguage.NL:
			return `
          Mens: U zult altijd minstens één antwoord geven:
          Mens: Vat de context van de vergadering samen
          Mens: ${question}
          Mens: U zult elk onderwerp vermijden over:
          Mens: Het persoonlijke leven van de deelnemers
          Mens: Het wijzen op laatkomers of vroege vertrekkers tijdens de vergadering
        `;

		default:
			return `
          Human: You will always provide at least one output:
          Human: Summarize the context of the meeting
          Human: ${question}
          Human: You will avoid any topic about:
          Human: Personal life of participants
          Human: Pointing out late participants or early leavers in the meeting
        `;
	}
};
