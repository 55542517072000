import React, { useState } from 'react';
import styles from './todos.form.modal.module.scss';
import { useAppSelector } from '../../../store/hooks';
import { IconClose } from '../../../shared/utils/icon';
import { TodosContract } from '../../../store/todos/type';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import 'react-tooltip/dist/react-tooltip.css';
import TodosFormComponent from './todos.form.component';
import { RouteUrls } from '../../../routes/routes-config';

interface Props {
	toggle?: () => void;
	handleToggle: () => void;
	todo: TodosContract;
	attendees?: { name: string, email: string, }[];
}

const TodosFormModal: React.FC<Props> = ({ handleToggle, todo, attendees }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const { user } = useAppSelector((state) => state.user);

	const [updatedTodo, setUpdatedTodo] = React.useState<TodosContract>(todo);
	const [animation, setAnimation] = useState<boolean>(false);

	const params = useParams();
	const shareId = params.shareID;

	const storedAccessMail = localStorage.getItem(`accessMail/${shareId}`);

	const handleClose = () => {
		setAnimation(true);

		setTimeout(() => {
			handleToggle();
			if (location.search.includes('?create') || location.search.includes('?view')) {
				navigate(RouteUrls.Todos);
			}

			setAnimation(false);
		}, 300);
	};

	return (
		<>
			<div className={`${styles.container} ${animation ? styles.fadeOut : ''}`}>
				<div className={`${styles.modal} ${animation ? styles.slideOut : ''}`}>
					<div className={styles.closeButton}>
						<div className={styles.icon} onClick={handleClose}>
							<IconClose />
						</div>
					</div>
					{updatedTodo && (
						<>
							{user ? (
								<TodosFormComponent
									todo={updatedTodo}
									userId={user.graphUserId}
									handleToggle={handleToggle}
									handleClose={handleClose}
									attendees={attendees}
								/>
							) : storedAccessMail ? (
								<TodosFormComponent
									todo={updatedTodo}
									userId={storedAccessMail}
									handleToggle={handleToggle}
									handleClose={handleClose}
									attendees={attendees}
								/>
							) : (
								<></>
							)}
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default TodosFormModal;
