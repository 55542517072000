/* eslint-disable unicorn/no-null */
import React from 'react';
import { TodosContract, TodosStates } from '../../../store/todos/type';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
	IconContact,
	IconDown,
	IconDownChev,
	IconMeetings,
	IconMeetingsPage,
	IconTag,
	IconText,
	IconUp,
} from '../../../shared/utils/icon';
import TodosRowComponent from './todos-row.component';
import styles from '../todos.module.scss';
import { getDateFormatL } from '../../../shared/utils/date.util';
import TodosFormModal from '../modals/todos.form.modal';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { RouteUrls } from '../../../routes/routes-config';
import { reverseTodos } from '../../../store/todos/slice';
import { reverseTodosProject } from '../../../store/project/slice';

interface Meeting {
	meetingName: string;
	meetingStartDate: string;
	meetingGraphId: string;
	graphiCalUId: string;
}

interface Props {
	todosAssignee: TodosContract[];
	meetings: Meeting[];
	height?: string;
	attendees?: { name: string, email: string, }[];
}

const TodosViewMeetings: React.FC<Props> = ({ todosAssignee, meetings, height, attendees }) => {
	const { t } = useTranslation();
	const location = useLocation();
	const { user } = useAppSelector((state) => state.user);
	const dispatch = useAppDispatch();

	const [openUpdate, setOpenUpdate] = React.useState(false);
	const [todoToUpdate, setTodoToUpdate] = React.useState<TodosContract | undefined>(undefined);

	const handleUpdate = (NoteTD: TodosContract) => {
		setTodoToUpdate(NoteTD);
		setOpenUpdate(!openUpdate);
	};

	const toggleUpdate = () => {
		setOpenUpdate(!openUpdate);
	};

	const [seeList, setSeeList] = React.useState<boolean[]>(meetings.map(() => true));
	const [none, setNone] = React.useState(true);

	const handleNone = () => {
		setNone(!none);
	};

	const handleSee = (index: number) => {
		setSeeList((prevSeeList) => {
			const newSeeList = [...prevSeeList];
			newSeeList[index] = !newSeeList[index];
			return newSeeList;
		});
		setNone(false);
	};

	const [arrow, setArrow] = React.useState(false);

	const handleArrow = () => {
		setArrow(!arrow);
		dispatch(reverseTodos());
		dispatch(reverseTodosProject());
	};

	React.useEffect(() => {
		if (location.search.includes('?create')) {
			const dullTodo = {
				id: '',
				title: 'new todo',
				text: '',
				state: TodosStates.TODO,
				dueDate: '',
				duration: 0,
				graphUserId: user ? user.graphUserId : '',
				noteId: '',
				assigneeDisplayName: user ? user.email : '',
				assigneeName: user ? user.name : '',
				graphiCalUId: null,
				meetingGraphId: null,
				meetingName: null,
				meetingStartDate: null,
				createdOn: new Date(),
				tags: [],
				archived: false,
				projectId: '',
			};

			const todo = dullTodo as unknown as TodosContract;

			if (todo) {
				handleUpdate(todo);
			}
		} else if (location.search.includes('?view')) {
			const id = location.search.split('/')[1];

			const todo = todosAssignee && todosAssignee.find((todoA) => todoA.id === id);

			if (todo) {
				handleUpdate(todo);
			}
		}
	}, [location]);

	const currentPath = window.location.pathname;
	const todosPagePath = RouteUrls.Todos;
	const renderTodos = currentPath === todosPagePath || currentPath === todosPagePath + '/';

	return (
		<div>
			<div className={styles.headTitle}>
				<div className={styles.flexHeader}>
					<div className={styles.title} style={{ color: '#2E4552' }}>
						<IconText /> {t('Todos')}
					</div>
					<div>
						{arrow === false ? (
							<span style={{ cursor: 'pointer' }} onClick={handleArrow}>
								<IconUp />
							</span>
						) : (
							<span style={{ cursor: 'pointer' }} onClick={handleArrow}>
								<IconDown />
							</span>
						)}
					</div>
				</div>
				<div className={styles.titleHeader}>
					<div className={styles.title}>
						<IconMeetings />
						{t('Due date')}
					</div>
				</div>
				{!renderTodos && (
					<div className={styles.titleHeader}>
						<div className={styles.title}>
							<IconContact />
							{t('Assignees')}
						</div>
					</div>
				)}
				<div className={styles.titleHeader}>
					<div className={styles.title}>
						<IconTag /> {t('Tags')}
					</div>
				</div>
				{renderTodos && (
					<div className={styles.titleHeader}>
						<div className={styles.title}>
							<IconMeetingsPage /> {t('Connected meeting')}
						</div>
					</div>
				)}
				{renderTodos && (
					<div className={styles.titleHeader}>
						<div className={styles.title}>
							<IconMeetingsPage /> {t('Connected project')}
						</div>
					</div>
				)}

				<div className={styles.endColumn}></div>
			</div>
			<div style={{ height: height ?? 'min-content', overflowY: 'auto' }}>
				{meetings
					.filter((meeting) => meeting.meetingName !== null)
					.map((meeting, meetingIndex) => (
						<div key={meeting.meetingGraphId}>
							<div className={styles.cat} onClick={() => handleSee(meetingIndex)}>
								<div className={styles.titleCat}>
									<span
										style={{
											transform: seeList[meetingIndex] ? 'rotate(0deg)' : 'rotate(-90deg)',
											transition: '0.1s',
											fontSize: '12px',
										}}>
										<IconDownChev />
									</span>
									<span>{`${meeting.meetingName} (${getDateFormatL(
										meeting.meetingStartDate,
									)})`}</span>
									<span className={styles.number}>
										{
											todosAssignee.filter(
												(todoA) =>
													todoA.state !== TodosStates.DONE &&
													todoA.meetingName === meeting.meetingName &&
													todoA.meetingStartDate === meeting.meetingStartDate,
											).length
										}
									</span>
								</div>
							</div>
							{seeList[meetingIndex] && (
								<TodosRowComponent
									todos={todosAssignee.filter(
										(todoA) =>
											todoA.meetingName === meeting.meetingName &&
											todoA.meetingStartDate === meeting.meetingStartDate,
									)}
									meetingIndex={meetingIndex}
									handleUpdate={handleUpdate}
								/>
							)}
						</div>
					))}

				{meetings
					.filter((meeting) => meeting.meetingName === null)
					.map((meeting, meetingIndexNone) => (
						<div key={meeting.meetingGraphId}>
							<div className={styles.cat} onClick={handleNone}>
								<div className={styles.titleCat}>
									<span
										style={{
											transform: none ? 'rotate(0deg)' : 'rotate(-90deg)',
											transition: '0.1s',
											fontSize: '12px',
										}}>
										<IconDownChev />
									</span>
									<span>{t('None')}</span>
									<span className={styles.number}>
										{
											todosAssignee.filter(
												(todoA) =>
													todoA.state !== TodosStates.DONE &&
													todoA.meetingName === meeting.meetingName &&
													todoA.meetingStartDate === meeting.meetingStartDate,
											).length
										}
									</span>
								</div>
							</div>
							{none &&
								todosAssignee.some(
									(todoA) =>
										todoA.meetingName === meeting.meetingName &&
										todoA.meetingStartDate === meeting.meetingStartDate,
								) && (
									<TodosRowComponent
										todos={todosAssignee.filter(
											(todoA) =>
												todoA.meetingName === meeting.meetingName &&
												todoA.meetingStartDate === meeting.meetingStartDate,
										)}
										meetingIndex={meetingIndexNone}
										handleUpdate={handleUpdate}
									/>
								)}
						</div>
					))}
				{openUpdate && todoToUpdate && (
					<TodosFormModal attendees={attendees} toggle={toggleUpdate} handleToggle={toggleUpdate} todo={todoToUpdate} />
				)}
			</div>
		</div>
	);
};

export default TodosViewMeetings;
