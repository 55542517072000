import React from 'react';

export interface Section {
	title: string;
	content: string | string[];
}

export const parseTextToRemirrorJson = (text: string): string => {
	const lines = text.split('\n');
	const content: any[] = [];

	let currentList: any[] = [];

	lines.forEach((line) => {
		line = line.trim();
		if (line.startsWith('###')) {
			if (currentList.length > 0) {
				content.push({ type: 'bulletList', content: currentList });
				currentList = [];
			}
			content.push(
				{ type: 'paragraph', content: [] },
				{
					type: 'heading',
					attrs: { level: 3 },
					content: [{ type: 'text', text: line.replace('###', '').trim() }],
				},
				{ type: 'paragraph', content: [] },
			);
		} else if (line.startsWith('- **') || line.startsWith('-')) {
			const cleanText = line
				.replace(/^- \*\*/, '')
				.replace(/\*\*\*$/, '')
				.replace('**', '')
				.replace('-', '')
				.trim();
			currentList.push({
				type: 'listItem',
				content: [
					{
						type: 'paragraph',
						content: [{ type: 'text', text: cleanText, marks: [{ type: 'bold' }] }],
					},
				],
			});
		} else if (line) {
			if (currentList.length > 0) {
				content.push({ type: 'bulletList', content: currentList });
				currentList = [];
			}
			content.push({
				type: 'paragraph',
				content: [{ type: 'text', text: line.trim() }],
			});
		}
	});

	if (currentList.length > 0) {
		content.push({ type: 'bulletList', content: currentList });
	}

	return JSON.stringify({ type: 'doc', content });
};

export function transformToRemirrorFormat(data: Section[]) {
	const content = data.flatMap((section) => {
		const titleNode = {
			type: 'heading',
			attrs: { level: 2 },
			content: [{ type: 'text', text: section.title }],
		};

		const spaceNode = { type: 'paragraph', content: [{ type: 'text', text: '' }] };

		const contentNodes =
			typeof section.content === 'string'
				? [
						{
							type: 'paragraph',
							content: [{ type: 'text', text: section.content }],
						},
				  ]
				: section.content.map((item) => ({
						type: 'bulletList',
						content: [
							{
								type: 'listItem',
								content: [
									{
										type: 'paragraph',
										content: [{ type: 'text', text: item }],
									},
								],
							},
						],
				  }));

		return [titleNode, spaceNode, ...contentNodes, spaceNode];
	});

	return JSON.stringify({ type: 'doc', content });
}
